import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchRealDemos } from '../actions';
import ReactTable from 'react-table';

class DemoRealList extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.props.fetchRealDemos().then((resp) => {
      console.log(this.props);
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <ReactTable
        data={this.props.demo}
        columns={[
          { Header: 'SKU', accessor: 'sku', width: 150 },
          { Header: 'Brand', accessor: 'brand', width: 200 },
          { Header: 'Name', accessor: 'name', width: 400 },
          { Header: 'Status', accessor: 'm2.status', width: 150 },

          { Header: 'Demo Price', accessor: 'm2.special_price', width: 100, style: { textAlign: 'right' } },
          { Header: 'Retail', accessor: 'm2.price', width: 100, style: { textAlign: 'right' } },
          {
            Header: 'Discount',
            accessor: 'discount',
            style: { textAlign: 'right' },
            width: 100,
            Cell: (row) => {
              let percent = row.original.discount != '-' ? Math.floor(row.original.discount * 100) + '%' : '';
              return percent;
            },
          },
          { Header: 'Quantity', accessor: 'bpOnHand', width: 100, style: { textAlign: 'center' } },
          { Header: 'STSLS', accessor: 'purchasing.stockTimeSinceLastSale', style: { textAlign: 'right' }, width: 100 },
        ]}
        style={{ textAlign: 'left', border: '1px', height: '700px' }}
        defaultSorted={[{ id: 'brand', desc: false }]}
        showPagination
        filterable
        defaultFilterMethod={(filter, row) => {
          let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
          return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
        }}
        minRows={10}
        pageSize={100}
        className="-striped -highlight"
        loading={this.state.loading}
        loadingText="Loading..."
      />
    );
  }
}

function mapStateToProps({ demo }) {
  return { demo };
}
const mapDispatchToProps = {
  fetchRealDemos,
};
export default connect(mapStateToProps, mapDispatchToProps)(DemoRealList);
