import { PROCESS_TRANSFER } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case PROCESS_TRANSFER:
      draft.response = action.payload;
      return;
  }
}, []);
