import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchConfigurables, fetchChildrenBPNames } from '../actions';
import { tabQuery } from './custom-js';

import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Button from '@material-ui/core/Button';
import PaperPage from './PaperPage';
import ReactTooltip from 'react-tooltip';

class Configurable extends Component {
  state = {
    loading: true,
    namesLoading: true,
    tabs: tabQuery(this)
  };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };

  componentDidMount() {
    this.props.fetchConfigurables().then((resp) => {
      this.setState({ loading: false });
    });
    this.props.fetchChildrenBPNames().then((resp) => {
      this.setState({ namesLoading: false });
    });
  }

  configurableColumns = [
    {
      Header: 'Config',
      headerClassName: 'blue-masterheader',
      columns: [
        {
          Header: 'SKU',
          accessor: 'sku',
          width: 100,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Brand',
          accessor: 'brand',
          width: 150,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Name',
          accessor: 'name',
          width: 300,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Status',
          accessor: 'status',
          width: 75,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
        {
          Header: 'Stock',
          accessor: 'stock',
          width: 150,
          headerClassName: 'blue-header',
          className: 'lblue',
        },
      ],
    },
    {
      Header: 'Children',
      columns: [
        { Header: 'SKU', accessor: 'config.sku', width: 100 },
        {
          Header: 'Brand',
          accessor: 'config.brand',
          width: 150,
        },
        { Header: 'Name', accessor: 'config.name', width: 300 },
        { Header: 'Status', accessor: 'config.status', width: 75 },
        { Header: 'Stock', accessor: 'config.stock', width: 150 },
        { Header: 'Cable Len', accessor: 'config.cable_length_config' },
        { Header: 'Color', accessor: 'config.color' },
        { Header: 'Pk Size', accessor: 'config.pack_size' },
        { Header: 'Pk Size Cfg', accessor: 'config.pack_size_config' },
        { Header: 'Size', accessor: 'config.size' },
      ],
    },
  ];

  namesColumns = [
    { Header: 'Parent', accessor: 'parent', width: 100 },
    { Header: 'SKU', accessor: 'sku', width: 200 },
    { Header: 'BP Name', accessor: 'name', width: 600},
    { Header: 'MPN', accessor: 'mpn'},
    { Header: 'Cable Length', accessor: 'cable_length'},
    { Header: 'Color', accessor: 'color'},
    { Header: 'Pack Size', accessor: 'pack_size'},
    { Header: 'Pack Size Cfg', accessor: 'pack_size_config'},
    { Header: 'Size', accessor: 'size'},
  ];

  render() {
    return (
      <PaperPage maxWidth='90%'>
        <TopBar pageName='Configurable Products'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/magento/config/table/csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export Configs
            </Button>
            <Button
              variant='contained'
              href={'/api/magento/config/bpNames/csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export BP Names
            </Button>
          </TopBarRow>
        </TopBar>
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab data-tip data-for="table" label="Table" />
            <Tab data-tip data-for="bpNames" label="BP Names" />
          </Tabs>
        </AppBar>

        <ReactTooltip id="table" place="top" type="dark" effect="solid">
          Lookup configurables
        </ReactTooltip>
        <ReactTooltip id="bpNames" place="top" type="dark" effect="solid">
          Audit BP names for config children
        </ReactTooltip>

        {this.state.tabs === 0 && <ReactTable
            data={this.props.configurable.table}
            columns={this.configurableColumns}
            style={{ textAlign: 'left', border: 0, height: '1000px' }}
            defaultSorted={[{ id: 'brand', desc: false }]}
            showPagination
            filterable
            defaultFilterMethod={(filter, row) => {
              return (
                row[filter.id] &&
                filter.value &&
                row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
              );
            }}
            pageSize={100}
            className='-striped -highlight'
            loading={this.state.loading}
            loadingText='Loading...'
          />}

          {this.state.tabs === 1 && <ReactTable
            data={this.props.configurable.bpNames}
            columns={this.namesColumns}
            style={{ textAlign: 'left', border: 0, height: '1000px' }}
            showPagination
            filterable
            pageSize={100}
            className='-striped -highlight'
            loading={this.state.namesLoading}
            loadingText='Loading...'
          />}
      </PaperPage>
    );
  }
}

function mapStateToProps({ configurable }) {
  return { configurable };
}
const mapDispatchToProps = {
  fetchConfigurables,
  fetchChildrenBPNames,
};
export default connect(mapStateToProps, mapDispatchToProps)(Configurable);
