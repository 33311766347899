import { FETCH_EBAY_ERRORS, FETCH_EBAY_FAUXDEMOS } from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_EBAY_ERRORS:
      draft.ebayErrors = action.payload;
      return;
    case FETCH_EBAY_FAUXDEMOS:
      draft.ebayFauxDemos = action.payload;
      return;
  }
}, {});
