import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

const PaperPage = props => {
  const { xs, sm, md, lg, xl, maxWidth } = props;

  return (
    <Grid container justifyContent="center">
      <Grid item xs={xs} sm={sm} md={md} lg={lg} xl={xl} style={{ maxWidth }}>
        <Paper>{props.children}</Paper>
      </Grid>
    </Grid>
  );
};

PaperPage.defaultProps = {
  xs: 12,
  lg: 11,
  maxWidth: 1440
};

export default PaperPage;
