import React, { Component } from 'react';
import { connect } from 'react-redux';
import BrandTableBP from './BrandTableBP';
import BrandTableM2 from './BrandTableM2';

import { tabQuery, columnQuery } from './custom-js';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactTooltip from 'react-tooltip';
import TopBar from './TopBar';
import PaperPage from './PaperPage';

class Brands extends Component {
  state = { tabs: tabQuery(this) };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };
  render() {
    return (
      <PaperPage>
        {/* Header Bar */}
        <TopBar pageName="Brands" />

        {/* Tabs + Tooltips */}
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab data-tip data-for="brightpearl-table" label="Brightpearl" />
            <Tab data-tip data-for="magento-table" label="Magento" />
          </Tabs>
        </AppBar>

        <ReactTooltip id="brightpearl-table" place="top" type="dark" effect="solid">
          Brightpearl brands / vendors + blacklist
        </ReactTooltip>
        <ReactTooltip id="magento-table" place="top" type="dark" effect="solid">
          Magento brands
        </ReactTooltip>

        {this.state.tabs === 0 && <BrandTableBP filter={columnQuery(this)} />}
        {this.state.tabs === 1 && <BrandTableM2 filter={columnQuery(this)} />}
      </PaperPage>
    );
  }
}

export default connect()(Brands);
