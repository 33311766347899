import {
  FETCH_USED,
  CREATE_USED,
  CREDIT_USED,
  FETCH_USEDINVENTORYREPORT,
  FETCH_USEDNEWSKUREPORT,
  ASSOCIATE_NEWSKU,
  UPDATE_USED,
} from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_USED:
      draft.products = action.payload.products;
      draft.orders = action.payload.orders;
      return;
    case FETCH_USEDINVENTORYREPORT:
      draft.inventoryReport = action.payload;
      return;
    case FETCH_USEDNEWSKUREPORT:
      draft.newSkuReport = action.payload;
      return;
    case ASSOCIATE_NEWSKU:
      draft.response = action.payload;
      return;
    case CREATE_USED:
      draft.response = action.payload;
      return;
    case CREDIT_USED:
      draft.response = action.payload;
      return;
    case UPDATE_USED:
      draft.response = action.payload;

      let prodIndex = draft.products.findIndex(
        (element) => element._id === action.payload._id
      );
      let field = action.payload.field;
      draft.products[prodIndex][field] = action.payload.update;

      return;
  }
}, {});
