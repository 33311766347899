import { WEBHOOK_REVERB, FETCH_REVERB, UPDATE_REVERB } from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case WEBHOOK_REVERB:
      return action.payload;
    case FETCH_REVERB:
      draft.listings = action.payload;
      return;
    case UPDATE_REVERB:
      draft.response = action.payload;
      return;
  }
}, {});
