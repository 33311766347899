import { FETCH_DEMOS_FAUX, FETCH_DEMOS_FAUXLIST, ADD_DEMO_FAUX } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_DEMOS_FAUX:
      return action.payload;
    case FETCH_DEMOS_FAUXLIST:
      return action.payload;
    case ADD_DEMO_FAUX:
      let demoIndex;
      action.payload.forEach((demo) => {
        demoIndex = draft.findIndex((element) => {
          return element._id == demo._id;
        });
        if (demoIndex) {
          draft[demoIndex][demo.type] = demo.bool;
        }
      });
  }
}, []);
