import { CREATE_PO, FETCH_PURCHASETOTALS } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case CREATE_PO:
      return action.payload;
    case FETCH_PURCHASETOTALS:
      draft.purchaseTotals = action.payload;
      return;
  }
}, []);
