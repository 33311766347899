import { FETCH_BPBRANDS, CREATE_BP_BRAND, UPDATE_BRAND, UPDATE_BRANDS } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_BPBRANDS:
      return action.payload;
    case UPDATE_BRANDS:
      //puts the update response inside of the larger bpBrand payload
      draft.response = action.payload;
      return;
    case CREATE_BP_BRAND:
      draft.response = action.payload;
      return;
    case UPDATE_BRAND:
      let brandIndex = draft.findIndex((element) => element._id === action.payload._id);
      let field = action.payload.field.split('.')[1];

      if (draft[brandIndex].blacklist == undefined) {
        draft[brandIndex].blacklist = {};
      }
      draft[brandIndex].blacklist[field] = action.payload.update;
  }
}, []);
