import { FETCH_CATEGORIES, UPDATE_CATEGORY } from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES:
      return action.payload;
    case UPDATE_CATEGORY:
      let prodIndex = draft.findIndex(element => element._id === action.payload._id);
      draft.splice(prodIndex, 1);
  }
}, []);
