import { FETCH_CONFIGURABLEPRODUCTS, FETCH_CHILDRENBPNAMES } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_CONFIGURABLEPRODUCTS:
      draft.table = action.payload;
      return;
    case FETCH_CHILDRENBPNAMES:
      draft.bpNames = action.payload;
      return;
  }
}, {});
