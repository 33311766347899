import { FETCH_RELATEDSUGGESTIONS } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_RELATEDSUGGESTIONS:
      return action.payload;
  }
}, []);
