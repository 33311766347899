import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { blue, grey } from '@material-ui/core/colors';
import { darken } from '@material-ui/core/styles/colorManipulator';

import App from './components/App';
import reducers from './reducers';

const store = createStore(reducers, {}, applyMiddleware(reduxThunk));
// uncomment the following to print each state update to console for debugging
// store.subscribe(() => console.log(store.getState()));
const unsubscribe = store.subscribe(() => console.log(store.getState()));
function getTheme(uiTheme) {
  const theme = createTheme({
    direction: uiTheme.direction,
    nprogress: {
      color: uiTheme.paletteType === 'light' ? '#000' : '#fff',
    },
    palette: { ...uiTheme.paletteColors, type: uiTheme.paletteType },
  });

  return theme;
}

const theme = getTheme({
  direction: 'ltr',
  paletteType: 'light',
  paletteColors: {
    primary: {
      main: grey['200'],
    },
    secondary: {
      // Darken so we reach the AA contrast ratio level.
      main: darken(blue.A400, 0.0),
    },
  },
});

ReactDOM.render(
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </Provider>,
  document.querySelector('#root')
);
