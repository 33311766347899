import { FETCH_USERS, UPDATE_USER } from '../actions/types';
import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return action.payload;
    case UPDATE_USER:
      const index = draft.findIndex(element => element._id === action.payload._id);
      draft[index] = action.payload;
  }
}, []);
