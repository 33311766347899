import React, { Component } from 'react';
import { connect } from 'react-redux';

import TopBar from './TopBar';
import PaperPage from './PaperPage';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';

import PurchasingTable from './PurchasingTable';
import PurchasingForm from './PurchasingForm';

class Purchasing extends Component {
  render() {
    return (
      <PaperPage maxWidth="100%">
        {/* Header Bar */}
        <TopBar pageName="Purchasing">
          <TopBarRow justify="flex-end">
            <Button variant="contained" href={'/api/purchasing/report/csv'} label="Export" style={{ top: -5, marginLeft: 30 }}>
              Export
            </Button>
          </TopBarRow>
        </TopBar>

        <div>
          <PurchasingForm />
          <PurchasingTable />
        </div>
      </PaperPage>
    );
  }
}

export default connect()(Purchasing);
