import { FETCH_INVENTORY } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_INVENTORY:
      return action.payload;
  }
}, []);
