import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchIntegrations, createIntegration, deleteIntegration } from '../actions';
import ReactTable from 'react-table';
import Cancel from '@material-ui/icons/Cancel';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import PaperPage from './PaperPage';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import NewIntegration from './NewIntegration';

// importing for TopBar testing only
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class Integrations extends Component {
  state = {
    submitting: false,
    integrationName: ''
  };
  componentDidMount() {
    this.props.fetchIntegrations();
  }

  render() {
    return (
      <PaperPage maxWidth={900}>
        <TopBar pageName="Integrations">
          <TopBarRow justify="flex-end">
            <NewIntegration
              onSubmit={() => {
                this.setState({ submitting: true });
                this.props.createIntegration(this.state.integrationName).then(() => this.setState({ submitting: false }));
              }}
              submitting={this.state.submitting}
              integrationName={this.state.integrationName}
              onChange={integrationName => this.setState({ integrationName: integrationName })}
            />
          </TopBarRow>
          {/* BEGIN TEST */}
          <TopBarRow justify="center" spacing={2}>
            <Button variant="outlined">Test Button</Button>
            <Button variant="contained">Test</Button>
            <Button>Test Button</Button>
            <Button>Don't Press</Button>
          </TopBarRow>
          <TopBarRow justify="flex-start" spacing={2}>
            <Button variant="outlined">Test Button</Button>
            <Button variant="outlined">Test Button</Button>
            <FormControl style={{ minWidth: 120 }} margin={'none'}>
              <InputLabel htmlFor="age-simple">Age</InputLabel>
              <Select value={this.state.age} onChange={this.handleChange} inputProps={{ name: 'age', id: 'age-simple' }}>
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value={10}>Ten</MenuItem>
                <MenuItem value={20}>Twenty</MenuItem>
                <MenuItem value={30}>Thirty</MenuItem>
              </Select>
            </FormControl>
          </TopBarRow>
          <TopBarRow justify="space-between" spacing={2}>
            <Button variant="contained">Test Button</Button>
            <Button variant="outlined">Button</Button>
            <Button variant="contained">Test Button</Button>
            <Button variant="contained">Wow</Button>
            <Button variant="contained">Test Button</Button>
            <Button variant="contained">Test Button</Button>
          </TopBarRow>
          {/* END TEST */}
        </TopBar>

        <ReactTable
          data={this.props.integrations}
          columns={[
            { Header: 'Description', accessor: 'description' },
            { Header: 'Token', accessor: 'token', width: 375 },
            {
              Header: 'Delete',
              id: 'delete',
              accessor: '_id',
              width: 100,
              Cell: row => (
                <IconButton
                  style={{ width: '28px', height: '28px' }}
                  onClick={value => {
                    this.props.deleteIntegration(row.original._id);
                  }}
                  disableRipple
                >
                  <Cancel color="error" />
                </IconButton>
              )
            }
          ]}
          showPagination={false}
          minRows={10}
          style={{ lineHeight: '28px', textAlign: 'center', border: 0 }}
          className="-striped -highlight"
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ integrations }) {
  return { integrations };
}
const mapDispatchToProps = {
  fetchIntegrations,
  createIntegration,
  deleteIntegration
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Integrations);
