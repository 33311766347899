import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchSaleCalc } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';

class OverstocksSaleCalc extends Component {
  state = {
    loading: true,
    percentage: 0.25,
  };
  componentDidMount() {
    this.pullSales();
  }
  pullSales = () => {
    console.log('pull');
    this.setState({ loading: true });
    this.props.fetchSaleCalc(this.state.percentage).then((resp) => {
      console.log(this.state.percentage);
      this.setState({ loading: false });
    });
  };

  handleNewInput = (e) => {
    this.setState({ percentage: e.target.value });
  };

  textFilter = (filter, row) => {
    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
    return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
  };

  boolFilter = ({ filter, onChange }) => (
    <select
      onChange={(event) => onChange(event.target.value)}
      style={{ width: '100%' }}
      value={filter ? filter.value : 'all'}>
      <option value='all'>All</option>
      <option value='true'>true</option>
      <option value='false'>false</option>
    </select>
  );

  per = (value) => {
    return Math.floor(value * 100) + '%';
  };

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  priceColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 100,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 200,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: { textAlign: 'left' },
      width: 275,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'Retail',
      accessor: 'price',
      width: 90,
      Cell: (row) => this.formatter.format(row.value),
    },
    {
      Header: 'Special Price',
      accessor: 'special_price',
      width: 90,
      Cell: (row) => {
        console.log(row);
        if (row.value > 0) {
          return this.formatter.format(row.value);
        } else {
          return null;
        }
      },
    },
    {
      Header: 'Discount',
      accessor: 'discount',
      width: 90,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseFloat(filter.value / 100);
      },
      Cell: (row) => {
        if (row.value > 0) {
          return this.per(row.value);
        } else {
          return null;
        }
      },
    },

    {
      Header: 'MAP Status',
      accessor: 'brandDb.blacklist.mapStatus',
      width: 125,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'Clearance',
      accessor: 'brandDb.blacklist.clearance',
      width: 100,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'QTY',
      accessor: 'bp.bpOnHand',
      width: 50,
      style: { textAlign: 'center' },
    },
    {
      Header: 'Shelf',
      accessor: 'shelfLife',
      width: 50,
      style: { textAlign: 'center' },
    },
    {
      Header: 'Sale',
      accessor: 'pc_on_sale',
      width: 75,
      style: { textAlign: 'center' },
      Cell: (row) => (row.value ? 'X' : ''),
      Filter: ({ filter, onChange }) => this.boolFilter({ filter, onChange }),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        } else if (filter.value == 'true') {
          return row[filter.id];
        } else {
          return !row[filter.id];
        }
      },
    },
    {
      Header: 'Curr',
      accessor: 'currentSale',
      width: 75,
      style: { textAlign: 'center' },
      Cell: (row) => (row.value ? 'X' : ''),
      Filter: ({ filter, onChange }) => this.boolFilter({ filter, onChange }),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        } else if (filter.value == 'true') {
          return row[filter.id];
        } else {
          return !row[filter.id];
        }
      },
    },
    {
      Header: 'Sugg',
      accessor: 'saleSuggestion',
      width: 75,
      style: { textAlign: 'center' },
      Cell: (row) => (row.value ? 'X' : ''),
      Filter: ({ filter, onChange }) => this.boolFilter({ filter, onChange }),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        } else if (filter.value == 'true') {
          return row[filter.id];
        } else {
          return !row[filter.id];
        }
      },
    },
  ];

  render() {
    return (
      <div>
        <TopBar
          pageName='Sale Calc'
          subHeading='A filtered selection of sale-eligible items'>
          <TopBarRow justify='flex-end'>
            <form style={{ padding: 25 }} noValidate autoComplete='off'>
              <TextField
                value={this.state.percentage}
                onChange={this.handleNewInput}
                id='percentage'
                label='Sale Percentage'
                InputLabelProps={{ shrink: true }}
                placeholder='% integer'
                fullWidth
                margin='normal'
                style={{ marginBottom: 15 }}
              />
            </form>
            <Button
              variant='contained'
              onClick={this.pullSales}
              label='Refresh Table'
              style={{ top: -5, marginLeft: 30 }}>
              Refresh Table
            </Button>
            <Button
              variant='contained'
              href={'/api/on-sale'}
              label='Export Current Sales'
              style={{ top: -5, marginLeft: 30 }}>
              Export Current Sales
            </Button>
            <Button
              variant='contained'
              href={`/api/sale?type=csv&percentage=${this.state.percentage}`}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export Suggested Sales
            </Button>
          </TopBarRow>
        </TopBar>
        <ReactTable
          data={this.props.pricing}
          columns={this.priceColumns}
          style={{ textAlign: 'right', border: 0, height: '750px' }}
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return rowValue > parseInt(filter.value);
          }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo && rowInfo.row.currentSale)
              return { style: { background: '#c8e6c9' } };
            else return {};
          }}
        />
      </div>
    );
  }
}

function mapStateToProps({ pricing, auth }) {
  return { pricing, auth };
}
const mapDispatchToProps = {
  fetchSaleCalc,
};
export default connect(mapStateToProps, mapDispatchToProps)(OverstocksSaleCalc);
