import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsedPipelineReport } from '../actions';

import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

class UsedBarChart extends Component {
  state = { loading: true };
  componentDidMount() {
    this.props.fetchUsedPipelineReport().then(() => {
      this.setState({ loading: false });
    });
  }
  render() {
    // if (this.state.loading) {
    //   return <p style={{ textAlign: 'center' }}>Loading table...</p>;
    // }
    return (
      <div style={{ margin: '20px' }}>
        <h2>Pipeline Report</h2>
        <p>
          Average Time to List (Past 90): {this.props.usedReport.avgTtl} days
        </p>
        <ResponsiveContainer width='100%' minHeight={380}>
          <BarChart
            width={500}
            height={300}
            data={this.props.usedReport.stages}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='stage' />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar
              dataKey='count'
              fill='#34b4c9'
              activeBar={<Rectangle fill='#34b4c97a' stroke='#34b4c9' />}
            />
          </BarChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

function mapStateToProps({ usedReport }) {
  return { usedReport };
}
const mapDispatchToProps = {
  fetchUsedPipelineReport,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsedBarChart);
