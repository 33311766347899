import {
  FETCH_PREORDERS,
  FETCH_PURCHASING,
  FETCH_WAREHOUSES,
} from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_PURCHASING:
      draft.purchasing = action.payload;
      return;
    case FETCH_PREORDERS:
      draft.preorders = action.payload;
      return;
    case FETCH_WAREHOUSES:
      draft.warehouses = action.payload;
      return;
  }
}, {});
