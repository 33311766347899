import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTopSellers } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
import Button from '@material-ui/core/Button';

class TopSellers extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.props.fetchTopSellers().then((resp) => {
      this.setState({ loading: false });
    });
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  topSellerColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 120,
      style: { textAlign: 'left' },
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 200,
      style: { textAlign: 'left' },
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 320,
      style: { textAlign: 'left' },
    },
    {
      Header: 'Price',
      accessor: 'm2.price',
      width: 100,
      Cell: (row) => this.formatter.format(row.value),
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseInt(filter.value);
      },
    },
    {
      Header: 'Categories',
      accessor: 'm2.category_ids',
      width: 300,
      style: { textAlign: 'left' },
    },
    {
      Header: 'Total Qty',
      accessor: 'bpOnHand',
      width: 100,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseInt(filter.value);
      },
    },
    {
      Header: 'Shop New',
      accessor: 'bpShopOnHand',
      width: 100,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseInt(filter.value);
      },
    },
    {
      Header: 'Shop Demo',
      accessor: 'demoOnHand',
      width: 75,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseInt(filter.value);
      },
    },
    {
      Header: 'Sales / Mo.',
      accessor: 'sales',
      width: 100,
      Cell: (row) => Math.round(row.value),
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseInt(filter.value);
      },
    },
  ];

  render() {
    return (
      <PaperPage>
        <TopBar pageName="Top Sellers" subHeading="Blue rows have showroom inventory">
          <TopBarRow justify="flex-end">
            <Button variant="contained" href={'/api/audit/top-sellers/csv'} label="Export Preorders" style={{ top: -5, marginLeft: 30 }}>
              Export
            </Button>
          </TopBarRow>
        </TopBar>
        <ReactTable
          data={this.props.topSellers}
          columns={this.topSellerColumns}
          style={{ textAlign: 'center', border: 0 }}
          defaultSorted={[{ id: 'sales', desc: true }]}
          showPagination
          filterable
          defaultFiltered={[{ id: 'm2.price', value: 100 }]}
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
          }}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo != undefined && (rowInfo.row._original.bpShopOnHand > 0 || rowInfo.row._original.demoOnHand > 0)) return { style: { background: '#34b4c92e' } };
            else return {};
          }}
          minRows={10}
          pageSize={100}
          className="-highlight"
          loading={this.state.loading}
          loadingText="Loading..."
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ topSellers }) {
  return { topSellers };
}
const mapDispatchToProps = {
  fetchTopSellers,
};
export default connect(mapStateToProps, mapDispatchToProps)(TopSellers);
