import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchStockCorrections } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
import Button from '@material-ui/core/Button';
import ExternalLink from './ExternalLink';
import moment from 'moment';

class StockCorrection extends Component {
  state = {
    loading: true,
  };
  componentDidMount() {
    this.props.fetchStockCorrections().then((resp) => {
      this.setState({
        loading: false,
      });
    });
  }
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  stockCorrectionColumns = [

    {
      Header: 'Date', accessor: 'createdOn', width: 120, Cell: row => {
        return moment(row.original.createdOn).format('M/D/YY h:mm a')
      }
    },
    {
      Header: 'ID',
      accessor: 'goodsMovementId',
      width: 90,
      Cell: (row) => {
        if (row.value) {
          let url = `https://use1.brightpearlapp.com/p.php?sku=&dateFrom=&numResults=50&productName=&dateTo=&warehouseId=&productId=&goodsMovementId=${row.original.goodsMovementId}&sortby=&sort_dir=&p=report&report=product-audittrail&report_submit=1&submit2=Filter&results_from=1&results_to=50&output=screen`;
          return <ExternalLink href={url} content={row.value} />;
        }
      },
    },
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 120,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
      Cell: (row) => {
        if (row.value) {
          let url = `https://use1.brightpearlapp.com/p.php?p=report&report=product-audittrail&report_zone=products&report-type=product-audittrail&report_submit=1&numResults=500&productId=${row.original.bpId}`;
          return <ExternalLink href={url} content={row.value} />;
        }
      },
    },
    {
      Header: 'Name',
      accessor: 'bpName',
      width: 400,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    { Header: 'QTY', accessor: 'quantity', style: { textAlign: 'center' }, width: 60 },
    { Header: 'Retail', accessor: 'price', width: 100, style: { textAlign: 'right' }, Cell: (row) => this.formatter.format(row.value) },
    { Header: 'Cost', accessor: 'productValue', width: 100, style: { textAlign: 'right' }, Cell: (row) => this.formatter.format(row.value) },
    { Header: 'Total', accessor: 'totalValue', width: 100, style: { textAlign: 'right' }, Cell: (row) => this.formatter.format(row.value) },
    { Header: 'Warehouse', accessor: 'warehouse', width: 100 },
    { Header: 'Creator', accessor: 'createdBy', width: 100 },
    { Header: 'Note', accessor: 'reason' },


  ];

  render() {

    return (
      <PaperPage maxWidth="80%">
        <TopBar pageName="Stock Corrections">
          <TopBarRow justify="flex-end">
            <Button variant="contained" href={'/api/stock-corrections/csv'} label="Export Stock Corrections" style={{ top: -5, marginLeft: 30 }}>
              Export Stock Corrections
            </Button>
          </TopBarRow>
        </TopBar>
        <ReactTable
          data={this.props.stockCorrections}
          columns={this.stockCorrectionColumns}
          style={{ textAlign: 'left', border: 0 }}
          defaultSorted={[{ id: 'createdOn', desc: true }]}
          showPagination
          filterable
          loading={this.state.loading}
          loadingText="Loading..."
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return rowValue > parseInt(filter.value);
          }}
          minRows={10}
          pageSize={100}
          className="-striped -highlight"
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ stockCorrections }) {
  return { stockCorrections };
}
const mapDispatchToProps = {
  fetchStockCorrections,
};
export default connect(mapStateToProps, mapDispatchToProps)(StockCorrection);
