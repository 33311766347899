import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUserList, toggleAdmin } from '../actions';
import PaperPage from './PaperPage';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import ToggleButton from 'react-toggle-button';

class UserList extends Component {
  componentDidMount() {
    this.props.fetchUserList();
  }

  render() {
    return (
      <PaperPage xs={9}>
        <ReactTable
          data={this.props.users}
          columns={[
            { Header: 'Name', accessor: 'name' },
            { Header: 'Email', id: 'email', accessor: d => d.emails[0] },
            { Header: 'Admin', id: 'admin', accessor: d => d.admin.toString() },
            {
              Header: 'Admin Toggle',
              id: 'adminToggle',
              accessor: '_id',
              Cell: row => (
                <ToggleButton
                  value={row.original.admin}
                  onToggle={value => {
                    this.props.toggleAdmin(row.original._id, !value);
                  }}
                />
              )
            }
          ]}
          showPagination={false}
          minRows={10}
          className="-striped -highlight"
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ users }) {
  return { users };
}
const mapDispatchToProps = {
  fetchUserList,
  toggleAdmin
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserList);
