import { FETCH_AUDIT, BLACKLIST_PRODUCT } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_AUDIT:
      return action.payload;
    case BLACKLIST_PRODUCT:
      let prodIndex = draft.findIndex(element => element._id === action.payload._id);
      draft.splice(prodIndex, 1);
  }
}, []);
