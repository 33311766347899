import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { fetchEbayErrors, fetchEbayFauxDemos } from '../actions';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';
import PaperPage from './PaperPage';
import ReactTable from 'react-table';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import 'react-table/react-table.css';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

const TabContainer = props => (
  <Typography component="div" style={{ padding: 8 * 3 }}>
    {props.children}
  </Typography>
);

const filterFunction = (filter, row, column) => {
  const id = filter.pivotId || filter.id;
  return row[id] !== undefined
    ? String(row[id])
        .toLowerCase()
        .startsWith(filter.value.toLowerCase())
    : true;
};

class Ebay extends Component {
  state = {
    tabs: 0,
    triggeringRoutine: false,
    fauxAddSkus: ''
  };

  componentDidMount() {
    this.props.fetchEbayErrors();
  }

  handleChange = (event, value) => {
    const tabs = { ebayErrors: this.props.fetchEbayErrors, ebayFauxDemos: this.props.fetchEbayFauxDemos };
    const tabIndex = Object.keys(tabs);
    const newTabSelection = tabIndex[value];
    console.log(newTabSelection);
    if (newTabSelection !== 'ebayErrors') {
      tabs[newTabSelection]();
    }
    this.setState({ tabs: value });
  };

  render() {
    const { tabs } = this.state;

    return (
      <PaperPage>
        <TopBar pageName="Ebay">
          <TopBarRow justify="flex-end" spacing={16}>
            <Button variant="contained" href={'/api/ebay/errors/csv'} label="Export Errors" style={{ width: 250, marginLeft: 20, height: 30, marginTop: 10 }}>
              Export Errors
            </Button>
          </TopBarRow>
        </TopBar>
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs value={tabs} onChange={this.handleChange}>
            <Tab label="Ebay Errors" />
            <Tab label="Faux Demos" />
          </Tabs>
        </AppBar>
        {tabs === 0 && (
          <TabContainer>
            <ReactTable
              data={this.props.ebay.ebayErrors}
              columns={[
                { Header: 'Date', accessor: 'createdAt', width: 115 },
                { Header: 'SKU', accessor: 'sku', width: 100 },
                { Header: 'Brand', accessor: 'product.brand', width: 150 },
                { Header: 'Magento Name', accessor: 'product.name', width: 300 },
                { Header: 'Error Message', accessor: 'listingErrors' }
              ]}
              filterable={true}
              defaultFilterMethod={filterFunction}
              minRows={20}
              className="-striped -highlight"
            />
          </TabContainer>
        )}
        {tabs === 1 && (
          <TabContainer>
            <TopBar pageName="Faux Demos">
              <TopBarRow justify="flex-end" spacing={16}>
                <div style={{ display: 'flex', width: 600 }}>
                  <TextField
                    label={'Comma separated SKUs to add'}
                    fullWidth={true} // value={integrationName} // onChange={e => onChange(e.target.value)}
                    color="primary"
                  />
                  <Button variant="contained" href={'/api/magento/exportcatalog'} label="Export Catalog" style={{ width: 250, marginLeft: 20, height: 30, marginTop: 10 }}>
                    Export Catalog
                  </Button>
                </div>
              </TopBarRow>
            </TopBar>
            <ReactTable
              data={this.props.ebay.ebayFauxDemos}
              columns={
                [
                  { Header: 'SKU', accessor: 'sku', width: 100 },
                  { Header: 'ItemID', accessor: 'ebayListings.itemId', width: 120 },
                  { Header: 'Title', accessor: 'ebayListings.title' },
                  { Header: 'Ebay Cond', accessor: 'condition', width: 90 },
                  { Header: 'Last Full Revision', accessor: 'lastFullEbayRevision', width: 150 }
                ]
                // { Header: 'Magento Name', accessor: 'product.name', width: 300 },
                // { Header: 'Error Message', accessor: 'message' }
              }
              filterable={true}
              defaultFilterMethod={filterFunction}
              minRows={20}
              className="-striped -highlight"
            />
          </TabContainer>
        )}
      </PaperPage>
    );
  }
}

function mapStateToProps({ ebay }) {
  return { ebay };
}
const mapDispatchToProps = {
  fetchEbayErrors,
  fetchEbayFauxDemos
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Ebay);
