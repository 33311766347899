import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

import {
  fetchReturnOrders,
  fetchWarrantyOrderStatuses,
  updateInternalNote,
  updateInternalNoteUI,
  updateRMANumber,
  updateRMANumberUI,
  updateTrackingNumber,
  updateTrackingNumberUI,
  updateSerialNumber,
  updateSerialNumberUI,
  updateWarrantyEmail,
  updateWarrantyNotes,
  updateWarrantyNotesUI,
  updateWarrantyOrderStatus,
  updateWarrantyOrderStatusUI,
} from '../actions'
import Button from '@material-ui/core/Button'
import 'react-table/react-table.css'
import ReactTable from 'react-table'
import ExternalLink from './ExternalLink'
import TextareaAutosize from '@material-ui/core/TextareaAutosize'
import Typography from '@material-ui/core/Typography'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import LinearProgress from '@material-ui/core/LinearProgress'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import InputLabel from '@material-ui/core/InputLabel'
import Checkbox from '@material-ui/core/Checkbox'
import TextField from '@material-ui/core/TextField'


class WarrantyStatusTable extends Component {

  state = {
    loading: true,
    statusFilter: [6, 95, 96, 97, 100, 101],
    selectedStatusHistory: { poId: undefined, statusHistory: [] },
    statusModal: false,
    emailStatus: 'NONE', // NONE, READY, UPDATING, SENDING, SENT, FAILED
    emailError: '',
    emailConfirmModal: false,
    emailConfirmData: {
      index: undefined,         // The row index of the RO
      vendorName: undefined,    // The vendor's name
      vendorId: undefined,
      brandName: undefined,     // The brand name
      vendorEmail: undefined,    // The vendor's email address
      roId: undefined,
      products: []
    }
  }

  componentDidMount() {
    this.props.fetchWarrantyOrderStatuses().then(() => {
      return this.props.fetchReturnOrders()
    }).then(() => {
      this.setState({
        loading: false
      })
    })
  }

  searchFilter = (filter, row) => {
    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
    return (
      rowValue.toString().toLowerCase().indexOf(filter.value.toLowerCase()) > -1
    );
  };

  excludeFilter = (filter, row) => {
    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
    return (
      rowValue.toString().toLowerCase().indexOf(filter.value.toLowerCase()) == -1
    );
  }

  defaultSortMethod = (a, b, desc) => {
    // force null and undefined to the bottom
    a = (a === null || a === undefined) ? -Infinity : a
    b = (b === null || b === undefined) ? -Infinity : b
    // force any string values to lowercase
    a = a === 'string' ? a.toLowerCase() : a
    b = b === 'string' ? b.toLowerCase() : b
    // Return either 1 or -1 to indicate a sort priority
    if (a > b) {
      return 1
    }
    if (a < b) {
      return -1
    }
    // returning 0, undefined or any falsey value will use subsequent sorts or the index as a tiebreaker
    return 0
  }

  bpPOUrl = "https://use1.brightpearlapp.com/patt-op.php" +
    "?scode=invoice&oID="
  columns = [
    {
      Header: 'Vendor',
      accessor: 'vendorName',
    },
    {
      Header: 'Brand',
      accessor: 'brandName',
    },
    {
      expander: true,
      Header: 'Products',
      accessor: "_original",
      width: 80,
      sortable: true,
      defaultSortDesc: true,
      sortMethod: (a, b, desc) => {
        return this.defaultSortMethod(a.quantity, b.quantity, desc)
      },
      Expander: ({ isExpanded, row }) => {
        return (
          <div style={{ display: 'inline-flex', paddingTop: 6 }}>
            ({row._original.quantity})
            {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </div>
        )
      }
    },
    {
      Header: 'PO',
      accessor: 'poId',
      width: 70,
      Cell: (row) => (
        <ExternalLink
          href={this.bpPOUrl + row.value}
          content={row.value}
        />
      )
    },

    {
      Header: 'PC',
      accessor: 'pcId',
      width: 70,
      Cell: (row) => (
        <ExternalLink
          href={this.bpPOUrl + row.value}
          content={row.value}
        />
      )
    },
    {
      Header: 'Tracking #',
      accessor: 'trackingNumber',
      width: 160,
      Cell: (row) => (
        <TextareaAutosize
          value={row.value || ""}
          placeholder="tracking #"
          style={{
            fontSize: 13,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
          }}
          onBlur={() => {
            this.props.updateTrackingNumber({
              returnOrderId: row.original._id,
              newTrackingNumber: row.original.trackingNumber
            })
          }}
          onChange={(event) => {
            this.props.updateTrackingNumberUI({
              returnOrderId: row.original._id,
              newTrackingNumber: event.target.value
            })
          }}
        />
      )
    },
    {
      Header: 'RMA #',
      accessor: 'rmaNumber',
      width: 160,
      Cell: (row) => (
        <TextareaAutosize
          value={row.value || ""}
          placeholder="RMA #"
          style={{
            fontSize: 13,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
          }}
          onBlur={() => {
            this.props.updateRMANumber({
              returnOrderId: row.original._id,
              newRMANumber: row.original.rmaNumber
            })
          }}
          onChange={(event) => {
            this.props.updateRMANumberUI({
              returnOrderId: row.original._id,
              newRMANumber: event.target.value
            })
          }}
        />
      )
    },
    {
      Header: 'Notes',
      accessor: 'notes',
      Cell: (row) => (
        <TextareaAutosize
          value={row.value || ""}
          placeholder="additional notes"
          style={{
            fontSize: 13,
            fontFamily: "Roboto, Helvetica, Arial, sans-serif",
            fontWeight: 400,
          }}
          onChange={(event) => {
            this.props.updateWarrantyNotesUI({
              returnOrderId: row.original._id,
              newNotes: event.target.value
            })
          }}
          onBlur={() => {
            this.props.updateWarrantyNotes({
              returnOrderId: row.original._id,
              newNotes: row.original.notes
            })
          }}
        />
      )
    },
    {
      Header: 'Status',
      accessor: 'status',
      width: 190,
      filterMethod: (filter, row) => {
        return filter.value.includes(row._original.status.id)
      },
      sortMethod: (a, b, desc) => {
        return this.defaultSortMethod(a.id, b.id, desc)
      },
      Cell: (row) => {
        const theme = createTheme({
          overrides: {
            MuiMenuItem: {
              root: {
                "&:hover": {
                  'text-decoration': 'underline',
                },
                "&$selected": {
                  'font-weight': 'bold',
                }
              }
            }
          }
        })
        const orderStatuses = this.props.warranty.orderStatuses
        const options = orderStatuses.map((orderStatus, index) => {
          return (
            <MenuItem
              key={index}
              value={orderStatus.statusId}
              style={{ fontSize: 14, backgroundColor: orderStatus.color }}
            >
              {orderStatus.name}
            </MenuItem>
          )
        })
        const currentStatus = orderStatuses.find((status) =>
          status.statusId == row.value.id)
        const color = currentStatus ? currentStatus.color : ''
        return (
          <ThemeProvider theme={theme}>
            <FormControl variant='outlined' size='small' fullWidth>
              <Select
                value={row.value.id}
                style={{ fontSize: 14, backgroundColor: color }}
                onChange={(event) => {
                  let newStatus = orderStatuses.find((status) =>
                    status.statusId == event.target.value)

                  this.props.updateWarrantyOrderStatusUI({
                    returnOrderId: row.original._id,
                    statusId: newStatus.statusId,
                    name: newStatus.name
                  }).then(() => {
                    console.log(this.props.warranty.response)
                    this.props.updateWarrantyOrderStatus({
                      orderId: row.original.poId,
                      statusId: newStatus.statusId
                    })
                  })
                }}
              >
                {options}
              </Select>
            </FormControl>
          </ThemeProvider>
        )
      },

      Filter: ({ filter, onChange }) => {
        let orderStatuses = this.props.warranty.orderStatuses
        if (!orderStatuses) {
          return
        }
        const theme = createTheme({
          overrides: {
            MuiMenuItem: {
              root: {
                'border-width': 'thin',
                'border-color': 'transparent',
                'border-style': 'solid',
                "&:hover": {
                  'text-decoration': 'underline',
                  'border-width': 'thin',
                  'border-style': 'solid',
                  'border-color': 'black'
                },
              }
            }
          }
        })
        orderStatuses = [...orderStatuses]
        orderStatuses.push({
          color: '#EEEEEE',
          name: 'Deleted',
          statusId: -1
        })
        const options = orderStatuses.map((orderStatus, index) => {
          const statusChecked = this.state.statusFilter.indexOf(orderStatus.statusId) > -1
          return (
            <MenuItem
              key={index}
              value={orderStatus.statusId}
              style={{ fontSize: 14, backgroundColor: orderStatus.color }}
            >
              <Checkbox size='small' checked={statusChecked}
                style={{ padding: 3, color: 'black' }}
              />
              {orderStatus.name}
            </MenuItem>
          )
        })
        return (
          <ThemeProvider theme={theme}>
            <FormControl variant='outlined' size='small' margin='none' fullWidth>
              {/* <InputLabel id="status-filter-label">Status Filter</InputLabel> */}
              <Select
                style={{ height: 30, textAlign: 'left' }}
                multiple
                value={this.state.statusFilter}
                /* labelId="status-filter-label" */
                onChange={event => {
                  onChange(event.target.value)
                  this.setState({
                    statusFilter: event.target.value
                  })
                }}
                renderValue={(selected) => {
                  return (
                    <InputLabel>Status Filter</InputLabel>
                  )
                }}
              >
                {options}
              </Select>
            </FormControl>
          </ThemeProvider>
        )
      },

    },
    {
      Header: 'Email Vendor',
      accessor: '$',
      width: 120,
      filterable: false,
      sortable: false,
      style: {
        textAlign: 'center'
      },
      Cell: (row) => (
        <Button
          style={{ background: '#34b4c9' }}
          variant="contained"
          color="secondary"
          disabled={this.state.emailConfirmData.index == row.index}
          onClick={() => {
            this.setState({
              emailConfirmModal: true,
              emailStatus: 'READY',
              emailConfirmData: {
                index: row.index,
                vendorName: row.original.vendorName,
                vendorEmail: row.original.warrantyEmail,
                vendorId: row.original.bpVendorId,
                brandName: row.original.brandName,
                roId: row.original._id,
                products: row.original.products
              }
            })
          }}
        >
          Send
        </Button>
      )
    },
    {
      Header: ' Updated',
      accessor: 'updatedAt',
      width: 80,
      defaultSortDesc: true,
      Cell: (row) => {
        if (!row.value)
          return undefined
        let date = new Date(row.value)
        let slashDate = Intl.DateTimeFormat('en-US',
          { day: "2-digit", month: "2-digit", year: "2-digit" }
        ).format(date)
        return (
          <Button
            onClick={() => {
              this.setState({
                statusModal: true,
                selectedStatusHistory: {
                  poId: row.original.poId,
                  statusHistory: row.original.statusHistory
                }
              })
            }}
          >
            {slashDate}
          </Button>

        )
      }
    }
  ]
  brandSubcolumns = [
    {
      Header: 'SKU',
      accessor: '$',
      width: 140,
      Cell: (row) => {
        const bpUrl = 'https://use1.brightpearlapp.com'
        const bpAuditLink = `${bpUrl}/p.php?numResults=500&p=report&` +
          `report=product-audittrail&report_zone=products&report-type=` +
          `product-audittrail&report_submit=1&productId=` +
          `${row.original.bpProductId}`
        return (
          <ExternalLink
            href={bpAuditLink}
            content={row.original.sku}
          />
        )
      }
    },
    {
      Header: 'Qty',
      accessor: 'quantity',
      width: 40,
      textAlign: 'right'
    },
    {
      Header: 'Name',
      accessor: 'bpName',
      width: 260,
      Cell: (row) => (row.value.split(';')[1].trim())
    },
    {
      Header: 'Serial #',
      accessor: 'serialNumber',
      width: 140,
      Cell: (row) => {
        return (
          <TextareaAutosize
            value={row.value || ""}
            placeholder="serial #"
            style={{
              fontSize: 13,
              width: 120,
              // maxWidth: 380,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: 400,
            }}
            onBlur={() => {
              this.props.updateSerialNumber({
                returnOrderId: row.original.returnOrderId,
                roNoteId: row.original._id,
                newSerialNumber: row.original.serialNumber
              })
            }}
            onChange={(event) => {
              this.props.updateSerialNumberUI({
                returnOrderId: row.original.returnOrderId,
                roNoteId: row.original._id,
                newSerialNumber: event.target.value
              })
            }}
          />
        )
      }
    },
    {
      Header: 'Customer Note',
      accessor: 'customerNote',
      Cell: (row) => {
        return (
          <Typography
            style={{
              fontSize: 13,
              whiteSpace: 'pre-line',
            }}>
            {row.value}
          </Typography>)
      }
    },
    {
      Header: 'Internal Note',
      accessor: 'internalNote',
      Cell: (row) => {
        return (
          <TextareaAutosize
            value={row.value || ""}
            placeholder="internal note"
            style={{
              fontSize: 13,
              width: 280,
              maxWidth: 380,
              fontFamily: "Roboto, Helvetica, Arial, sans-serif",
              fontWeight: 400,
            }}
            onBlur={() => {
              this.props.updateInternalNote({
                returnOrderId: row.original.returnOrderId,
                roNoteId: row.original._id,
                newNote: row.original.internalNote
              })
            }}
            onChange={(event) => {
              this.props.updateInternalNoteUI({
                returnOrderId: row.original.returnOrderId,
                roNoteId: row.original._id,
                newNote: event.target.value
              })
            }}
          />
        )
      }
    },
    {
      Header: 'Sales Order | Sales Credit',
      accessor: '$',
      width: 200,
      Cell: (row) => {
        const bpUrl = 'https://use1.brightpearlapp.com'
        const bpSCLink = `${bpUrl}/patt-op.php?scode=invoice&` +
          `oID=${row.original.salesCreditId}`
        const bpRecieptLink = `${bpUrl}/template_print.php?` +
          `return-to-oid=${row.original.saledOrderId}&template_id=41` +
          `&oID=${row.original.salesOrderId}&note_id=` +
          `&return-to-oid=${row.original.salesOrderId}&contacts_id=3079#`
        return (
          row.original.salesCreditId &&
          <span>
            <Button>
              <ExternalLink
                href={bpRecieptLink}
                content={<ReceiptIcon />}
              />
            </Button>
            |
            <Button>
              <ExternalLink
                href={bpSCLink}
                content={row.original.salesCreditId}
              />
            </Button>
          </span>)
      }
    },
  ]
  render() {
    let statusHistoryModal = (
      <Dialog open={this.state.statusModal}>
        <DialogTitle>
          Status History for P0: {this.state.selectedStatusHistory.poId}
        </DialogTitle>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>From</TableCell>
              <TableCell>To</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.selectedStatusHistory.statusHistory.map(
              (statusHistory) => {
                const from = !statusHistory.from ? "" : statusHistory.from.name
                const to = !statusHistory.to ? "" : statusHistory.to.name
                let date = !statusHistory.date ? "" : statusHistory.date
                if (date != "") {
                  date = new Date(date)
                  date = Intl.DateTimeFormat('en-US',
                    {
                      day: "2-digit", month: "2-digit", year: "2-digit",
                      hour: "2-digit", minute: "2-digit"
                    }
                  ).format(date)
                }
                return (
                  <TableRow>
                    <TableCell>{from}</TableCell>
                    <TableCell>{to}</TableCell>
                    <TableCell>{date}</TableCell>
                  </TableRow>
                )
              })}
          </TableBody>
        </Table>
        <DialogActions>
          <Button onClick={() => { this.setState({ statusModal: false }) }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    )

    let emailConfirmModalText = () => {
      let emailStatus = this.state.emailStatus
      if (emailStatus == 'READY' || emailStatus == 'SENDING') {
        return (
          <>
            {
              'Are you sure you want to send an email to ' +
              this.state.emailConfirmData.vendorName +
              ' at this address?'

            }

          </>
        )
      } else if (this.state.emailStatus == 'SENT') {
        return (
          <>
            Email to {this.state.emailConfirmData.vendorName} sent
          </>
        )
      } else if (this.state.emailStatus == 'FAILED') {
        return (
          <>
            {'Failed to send email to ' + this.state.emailConfirmData.vendorEmail}
            {this.state.emailError}
          </>
        )
      }
    }

    let emailConfirmModalButtons = () => {
      let emailStatus = this.state.emailStatus
      if (emailStatus == 'READY' || emailStatus == 'SENDING') {
        return (
          <DialogActions>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={emailStatus == 'SENDING'}
              onClick={() => {
                const email = this.state.emailConfirmData
                email.index = undefined
                this.setState({
                  emailConfirmModal: false,
                  emailConfirmData: email
                })
              }}
              size="small">No</Button>
            <Button
              variant="contained"
              fullWidth={true}
              size="small"
              disabled={this.state.emailStatus == 'SENDING'}
              style={{ background: '#34b4c9' }}
              onClick={() => {
                this.setState({
                  emailStatus: 'SENDING'
                })
                const email = this.state.emailConfirmData

                this.props.handleSendEmail(
                  email.brandName,
                  email.products,
                  email.vendorEmail
                ).then(() => {
                  email.index = undefined
                  this.setState({
                    emailConfirmData: email,
                    emailStatus: 'SENT'
                  })
                }).catch((error) => {
                  email.index = undefined
                  this.setState({
                    emailConfirmData: email,
                    emailStatus: 'FAILED',
                    emailError: error.response.data
                  })
                })
              }}
            >
              {this.state.emailStatus == 'SENDING' ? 'Sending...' : 'Yes'}
            </Button>
          </DialogActions>
        )
      } else if (emailStatus == 'SENT' || emailStatus == 'FAILED') {
        return (
          <DialogActions>
            <Button
              variant="contained"
              fullWidth={true}
              disabled={this.state.emailStatus == 'SENDING'}
              onClick={() => {
                this.setState({
                  emailConfirmModal: false,
                  emailStatus: 'NONE'
                })
              }}
              size="small">OK</Button>
          </DialogActions>
        )
      }
    }

    let emailConfirmationModal = (
      <Dialog open={this.state.emailConfirmModal}>
        <DialogTitle>
          Send Email to {this.state.emailConfirmData.vendorName || ''}{' | '}
          {this.state.emailConfirmData.brandName || ''}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {'Warranty email address on file: '}

          </DialogContentText>

          <DialogActions>
            <TextField
              style={{ width: 280 }}
              value={this.state.emailConfirmData.vendorEmail || ''}
              onChange={(event) => {
                const emailData = this.state.emailConfirmData
                emailData.vendorEmail = event.target.value
                this.setState({
                  emailConfirmData: emailData
                })
              }}
            />
            <Button
              variant='contained'
              disabled = {this.state.emailStatus == 'UPDATING' }
              onClick={() => {
                this.setState({
                  emailStatus: 'UPDATING'
                })
                this.props.updateWarrantyEmail({
                  bpVendorId: this.state.emailConfirmData.vendorId,
                  warrantyEmail: this.state.emailConfirmData.vendorEmail,
                }).then(() => {
                  this.setState({
                    emailStatus: 'READY'
                  })
                })
              }}
            >Update Email</Button>
          </DialogActions>
        </DialogContent>
        <DialogContent>
          <DialogContentText style={{ whiteSpace: 'pre-line' }}>
            {emailConfirmModalText()}
          </DialogContentText>
        </DialogContent>
        {emailConfirmModalButtons()}
        {(this.state.emailStatus == 'SENDING') && (<LinearProgress />)}
      </Dialog>
    )
    return (
      <div>
        <ReactTable
          data={this.props.warranty.return_orders}
          resolveData={(return_orders) => return_orders.map(return_order => {
            return {
              ...return_order,
              quantity: return_order.products.reduce((count, item) => {
                return count + item.quantity
              }, 0)
            }
          })}
          columns={this.columns}
          filterable
          defaultSorted={[
            {
              id: 'vendorName',
              desc: false
            }
          ]}
          defaultFiltered={[
            {
              id: 'status',
              value: this.state.statusFilter
            }
          ]}
          collapseOnDataChange={false}
          defaultFilterMethod={(filter, row) =>
            this.searchFilter(filter, row)
          }
          SubComponent={(row) => {
            return (
              <ReactTable
                data={row.original.products}
                resolveData={(products) => products.map(product => {
                  return {
                    ...product,
                    returnOrderId: row.original._id
                  }
                })}
                columns={this.brandSubcolumns}
                style={{ height: 420 }}
                className='-striped -highlight'
                filterable
                defaultFilterMethod={(filter, row) =>
                  this.searchFilter(filter, row)
                }
              />
            )
          }}
        />
        {statusHistoryModal}
        {emailConfirmationModal}
      </div>
    )
  }
}

function mapStateToProps({ warranty }) {
  return { warranty }
}

const mapDispatchToProps = {
  fetchReturnOrders,
  fetchWarrantyOrderStatuses,
  updateInternalNote,
  updateInternalNoteUI,
  updateRMANumber,
  updateRMANumberUI,
  updateTrackingNumber,
  updateTrackingNumberUI,
  updateSerialNumber,
  updateSerialNumberUI,
  updateWarrantyEmail,
  updateWarrantyNotes,
  updateWarrantyNotesUI,
  updateWarrantyOrderStatus,
  updateWarrantyOrderStatusUI,
}
export default connect(mapStateToProps, mapDispatchToProps)(WarrantyStatusTable)
