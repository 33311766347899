import { FETCH_USEDPIPELINEREPORT } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_USEDPIPELINEREPORT:
      console.log('asdsdadsss');
      console.log(action.payload);
      return action.payload;
  }
}, {});
