import React from 'react';

const ExternalLink = props => {
  const { href, target, content } = props;
  return (
    <a href={href} target={target}>
      {content}
    </a>
  );
};

ExternalLink.defaultProps = { target: '_blank' };

export default ExternalLink;
