import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchOverstocks } from '../actions';
import ReactTable from 'react-table';

class OverstocksClearance extends Component {
  state = {
    tabs: 0,
    loading: true,
  };
  componentDidMount() {
    this.props.fetchOverstocks().then((resp) => {
      this.setState({ loading: false });
    });
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  render() {
    return (
      <div>
        <p>
          Items over $99 that haven't sold in over 4 months. Blue rows are in
          the shop.
        </p>
        <ReactTable
          data={this.props.pricing}
          columns={[
            {
              Header: 'SKU',
              accessor: 'sku',
              width: 150,
            },
            {
              Header: 'Brand',
              accessor: 'brand',
              width: 200,
            },
            {
              Header: 'Name',
              accessor: 'name',
              width: 450,
            },
            {
              Header: 'Price',
              accessor: 'price',
              width: 100,
              style: { textAlign: 'right' },
              Cell: (row) => this.formatter.format(row.value),
            },
            {
              Header: 'Discount',
              accessor: 'discount',
              width: 100,
              style: { textAlign: 'right' },
              Cell: (row) => row.value + '%',
            },
            {
              Header: 'QTY',
              accessor: 'bp.bpOnHand',
              width: 75,
              style: { textAlign: 'right' },
            },
            {
              Header: 'Clearance?',
              accessor: 'clearance',
              width: 100,
            },
            {
              Header: 'Strict?',
              accessor: 'brandDb.blacklist.mapStatus',
              width: 150,
              // Cell: (row) => {
              //   if (['MAP Private Coupon', 'MAP No Coupon', 'No Discounts'].indexOf(row.value) > -1) {
              //     return 'X';
              //   }
              //   return '';
              // },
            },
          ]}
          style={{ textAlign: 'left', border: 0 }}
          defaultSorted={[{ id: 'brand', desc: false }]}
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
        />
      </div>
    );
  }
}

function mapStateToProps({ pricing }) {
  return { pricing };
}
const mapDispatchToProps = {
  fetchOverstocks,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OverstocksClearance);
