import React, { Component } from 'react';
import { connect } from 'react-redux';
import OverstocksClearance from './OverstocksClearance';
import OverstocksSaleCalc from './OverstocksSaleCalc';

import { tabQuery } from './custom-js';

import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PaperPage from './PaperPage';

class Overstocks extends Component {
  state = { tabs: tabQuery(this) };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };
  render() {
    return (
      <PaperPage>
        {/* Header Bar */}
        <TopBar>
          <TopBarRow display='block'>
            <h2>Overstocks</h2>
          </TopBarRow>
        </TopBar>
        {/* Tabs + Tooltips */}
        <AppBar position='static' style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab data-tip data-for='sale-calc' label='Sale Calc' />
            <Tab data-tip data-for='clearance' label='Clearance' />
          </Tabs>
        </AppBar>

        {this.state.tabs === 0 && <OverstocksSaleCalc />}
        {this.state.tabs === 1 && <OverstocksClearance />}
      </PaperPage>
    );
  }
}

export default connect()(Overstocks);
