import { FETCH_USER } from '../actions/types';

// returns null, user model (action.payload), or false
export default (state = null, action) => {
  switch (action.type) {
    case FETCH_USER:
      //console.log(action.payload);
      return action.payload || false; // trickery. empty string interpretted to be false like value. so if it's an empty string or false, return false
    default:
      return state;
  }
};
