import { FETCH_INTEGRATIONS, CREATE_INTEGRATION, DELETE_INTEGRATION } from '../actions/types';
import produce from 'immer';

/**
 * immer provides "draft" copy of current state
 * draft can be mutated
 * if you don't return a new state, draft is returned
 * no need to `return draft`
 * immer returns og state if draft isn't mutated
 * if draft is mutated it updates state with the mutated draft
 * note that the draft is specific to this reducer's slice of the store's state
 * you can uncomment line in main client index.js to subscribe to state updates and print each one to console
 * you'll see that 'draft' in this reducer is scoped to the 'integrations' key of the store/state
 * the key is what we specify in our combineReducers function
 */
export default produce((draft, action) => {
  switch (action.type) {
    // we're fetching all integrations, so return a new state slice for integrations key
    case FETCH_INTEGRATIONS:
      return action.payload;
    /** we're creating a new integration, so push the new integration data
     * actions returns new document from the db save response
     * therefore is formatted the same as the items from the fetch
     */
    case CREATE_INTEGRATION:
      draft.push(action.payload);
      return;
    /** action responds with the db _id of the item deleted successfully.
     * use this to find its index and remove with splice
     */
    case DELETE_INTEGRATION:
      const index = draft.findIndex(item => item._id === action.payload);
      if (index > 0) {
        draft.splice(index, 1);
      }
      return;
  }
}, []);
