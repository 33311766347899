import React, { Component } from 'react';
import { connect } from 'react-redux';
import { createM2Brands, updateBrandPage } from '../actions';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Modal from '@material-ui/core/Modal';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ReactTooltip from 'react-tooltip';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';

import ExitIcon from '@material-ui/icons/ExitToApp';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
// import DownloadIcon from '@material-ui/icons/FileDownload';
import GetAppIcon from '@material-ui/icons/GetApp'
import WebIcon from '@material-ui/icons/Web';

import LinearProgress from '@material-ui/core/LinearProgress';
import ExternalLink from './ExternalLink';

class BrandForm extends Component {
  state = {
    responseModal: false,
    brandPageDialog: false,
    submitting: false,
    import: '',
    response: '',
  };
  handleNewInput = (e) => {
    this.setState({ import: e.target.value });
  };

  handleClose = () => {
    this.setState({ import: '', response: '', responseModal: false, brandPageDialog: false });
  };

  handleCreateM2Brand = () => {
    if (this.state.import.length > 0) {
      this.setState({ submitting: true });
      this.props.createM2Brands(this.state.import).then(() => {
        this.setState({ submitting: false, import: '', responseModal: true, response: this.props.m2Brand.response });
      });
    }
  };

  handleUpdatePage = () => {
    this.setState({ submitting: true });
    this.props.updateBrandPage().then(() => {
      this.setState({ submitting: false, import: '', brandPageDialog: true });
    });
  };

  render() {
    return (
      <div>
        {/* Brand creation response modal */}

        <Modal open={this.state.responseModal}>
          <Paper style={{ position: 'absolute', top: `20%`, left: `calc(50% - 500px)`, width: 1000, height: 400, outline: 0, padding: 25 }}>
            <ReactTable
              data={this.state.response}
              columns={[
                { Header: 'Brand', accessor: 'label', width: 250 },
                { Header: 'Status', accessor: 'message' },
              ]}
              style={{ height: '300px' }}
              className="-striped -highlight"
            />
            <Button style={{ marginTop: 15 }} onClick={this.handleClose} variant="contained" color="secondary">
              ok
            </Button>
          </Paper>
        </Modal>

        {/* Brand page update response modal */}

        <Dialog open={this.state.brandPageDialog} onClose={this.handleClose}>
          <DialogTitle>Success!</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Brand page updated. <ExternalLink href={'https://www.perfectcircuit.com/brands'} content={<ExitIcon style={{ fontSize: 20, marginBottom: -5 }} />} />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button style={{ marginTop: 15 }} onClick={this.handleClose} variant="contained" color="secondary">
              yeah alright
            </Button>
          </DialogActions>
        </Dialog>

        <form style={{ padding: 25 }} noValidate autoComplete="off">
          <TextField
            value={this.state.import}
            onChange={this.handleNewInput}
            id="brand"
            label="Create Brands"
            InputLabelProps={{ shrink: true }}
            placeholder="Pipe separated list of brand names"
            fullWidth
            margin="normal"
            style={{ marginBottom: 15 }}
          />
          <Button onClick={this.handleCreateM2Brand} variant="contained" disabled={this.state.submitting} style={{ marginRight: 15, background: '#f26322' }}>
            Add to Magento
            <CloudUploadIcon style={{ marginLeft: 10 }} />
          </Button>

          <Button data-tip data-for="refresh-page" onClick={this.handleUpdatePage} variant="contained" disabled={this.state.submitting} style={{ marginRight: 15, background: '#f26322' }}>
            Refresh Brand Page
            <WebIcon style={{ marginLeft: 10 }} />
          </Button>

          <ReactTooltip id="refresh-page" place="top" type="dark" effect="solid">
            Refresh perfectcircuit.com/brands
          </ReactTooltip>

          <Button variant="contained" href={'/api/brands/m2/csv'} label="Export">
            Export
            <GetAppIcon style={{ marginLeft: 10 }} />
          </Button>
          {this.state.submitting && <LinearProgress style={{ position: 'absolute', bottom: 0, left: 0, width: '100%', borderBottomLeftRadius: 4, borderBottomRightRadius: 4 }} color="secondary" />}
        </form>
      </div>
    );
  }
}
function mapStateToProps({ m2Brand }) {
  return { m2Brand };
}
const mapDispatchToProps = {
  createM2Brands,
  updateBrandPage,
};
export default connect(mapStateToProps, mapDispatchToProps)(BrandForm);
