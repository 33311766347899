import React, { Component } from 'react';
import { connect } from 'react-redux';

import TopBar from './TopBar';
import PaperPage from './PaperPage';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';
import { urlQuery } from './custom-js';

import SalesModal from './SalesModal';

class PurchasingSalesModal extends Component {
  state = urlQuery(this);

  render() {
    return (
      <PaperPage maxWidth='100%'>
        {/* Header Bar */}
        <TopBar pageName={this.state.name} />

        <SalesModal bpId={this.state.productId} />
      </PaperPage>
    );
  }
}

export default connect()(PurchasingSalesModal);
