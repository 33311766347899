import { FETCH_BOUGHTTOGETHER } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_BOUGHTTOGETHER:
      return action.payload;
  }
}, []);
