import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchWarehouses,
  fetchInventory,
  processTransfer,
  fulfillPreorders,
} from '../actions'
import axios from 'axios'

import ReactTable from 'react-table'
import PaperPage from './PaperPage'
import TopBar from './TopBar'
import TopBarRow from './TopBarRow'

import Paper from '@material-ui/core/Paper'
import Modal from '@material-ui/core/Modal'
import Button from '@material-ui/core/Button'

import InputLabel from '@material-ui/core/InputLabel'

import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'

import Typography from '@material-ui/core/Typography'
import CancelIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'
import LinearProgress from '@material-ui/core/LinearProgress'

class TransferForm extends Component {
  state = {
    responseModal: false,
    loading: true,
    submitting: false,
    type: '',
    items: [],
    fromName: '',
    toId: '',
    fromId: '',
    note: '',
    response: '',
    name: this.props.auth.name,
    customNoteStatus: false,
    maxLen: 36 - this.props.auth.name.length,
  }

  componentDidMount() {
    this.props.fetchWarehouses()
    this.props.fetchInventory().then((resp) => {
      this.setState({ loading: false })
    })
  }

  handleChange = (e) => {
    if (e.target.name == 'fromId') {
      let name = this.props.brightpearl.warehouses.find((wh) => {
        return wh.id == e.target.value
      }).name
      this.setState({ fromName: name })
      this.state.items.map((item, index) => {
        return this.searchSku(item.sku, index)
      })
    }
    this.setState({ [e.target.name]: e.target.value })
  }

  addRow() {
    this.setState({ items: [...this.state.items, { sku: '', qty: '' }] })
  }
  removeRow(index) {
    this.state.items.splice(index, 1)
    this.setState({ items: this.state.items })
  }

  searchSku = async (e, index) => {
    let skuQuery

    if (e.target) {
      skuQuery = e.target.value
    } else {
      skuQuery = e
    }
    console.log(index)
    let items = this.state.items
    items[index].sku = skuQuery
    items[index].msg = '...'
    items[index].bpName = ''
    items[index].bpId = ''

    this.setState({
      items: items,
    })

    let url = '/api/search/sku?sku=' + skuQuery

    let { data } = await axios.get(url)
    console.log(data)
    if (data.length == 0) {
      items[index].bpName = 'SKU not found'

      this.setState({
        items: items,
      })
      return
    }
    items[index].bpId = data[0].bpId
    items[index].bpName = data[0].bpName

    let match = this.props.inventory.find((line) => line.bpId == data[0].bpId)

    if (this.state.fromName.length > 0) {
      if (match && match[this.state.fromName] > 0) {
        items[index].msg =
          match[this.state.fromName] + ' on hand in ' + this.state.fromName
      } else {
        items[index].msg = '0 on hand in ' + this.state.fromName
      }
    } else {
      items[index].msg = 'select a WH'
    }
    console.log(items[index])
    this.setState({ items: items })
  }

  editQty(e, index) {
    this.state.items[index].qty = e.target.value
    this.setState({ items: this.state.items })
  }

  handleClose = () => {
    this.setState({
      response: '',
      responseModal: false,
      items: [],
      toId: '',
      fromId: '',
    })
  }

  processTransfer = (type) => {
    if (this.state.items.length > 0) {
      this.setState({ submitting: true, type: type }, () => {
        this.props.processTransfer(this.state).then(() => {
          this.setState({
            submitting: false,
            responseModal: true,
            response: this.props.transfer.response,
            type: '',
          })
        })
      })
    }
  }

  fulfillPreorders = () => {
    this.setState({ submitting: true }, () => {
      this.props.fulfillPreorders().then(() => {
        this.setState({
          submitting: false,
        })
      })
    })
  }

  generateLabelLink = () => {
    if (this.state.response.length > 0) {
      let items = this.state.response
        .map((row) => {
          return `${row.productId}:${row.quantity}`
        })
        .join(',')
      let link = `https://use1.brightpearlapp.com/console.php?p=print_labels&product-id-set=${items}`
      return link
    }
  }

  renderWarehouses = () => {
    return (
      this.props.brightpearl.warehouses &&
      this.props.brightpearl.warehouses.map((wh) => {
        return (
          <MenuItem value={wh.id} key={wh.name}>
            {wh.name}
          </MenuItem>
        )
      })
    )
  }

  render() {
    return (
      <PaperPage>
        <TopBar pageName='Transfers'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/brightpearl/transfer-report/csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export
            </Button>
          </TopBarRow>
        </TopBar>
        {/* response modal */}

        <Modal open={this.state.responseModal}>
          <Paper
            style={{
              position: 'absolute',
              top: `20%`,
              left: `calc(50% - 500px)`,
              width: 1000,
              height: 400,
              outline: 0,
              padding: 25,
            }}>
            <ReactTable
              data={this.state.response}
              columns={[
                { Header: 'ID', accessor: 'productId' },
                { Header: 'Quantity', accessor: 'quantity' },
                { Header: 'Status', accessor: 'status' },
              ]}
              style={{ height: '300px' }}
              className='-striped -highlight'
            />
            <Button
              style={{ marginTop: 15 }}
              onClick={this.handleClose}
              variant='contained'
              color='secondary'>
              ok
            </Button>
            <Button
              variant='contained'
              href={this.generateLabelLink()}
              label='Print Labels'
              target='_blank'
              style={{ marginTop: 15, marginLeft: 15 }}>
              Print Labels
            </Button>
          </Paper>
        </Modal>

        <div style={{ padding: 15 }}>
          <FormControl
            style={{ minWidth: 200, marginRight: 15, marginBottom: 15 }}>
            <InputLabel>From</InputLabel>
            <Select
              name='fromId'
              value={this.state.fromId}
              onChange={this.handleChange}>
              {this.renderWarehouses()}
            </Select>
          </FormControl>

          <FormControl style={{ minWidth: 200, marginRight: 20 }}>
            <InputLabel>To</InputLabel>
            <Select
              name='toId'
              value={this.state.toId}
              onChange={this.handleChange}>
              {this.renderWarehouses()}
            </Select>
          </FormControl>

          <FormControl>
            <TextField
              name='note'
              placeholder='reason'
              helperText={`max length: ${this.state.maxLen}`}
              inputProps={{ maxLength: this.state.maxLen }}
              disabled={this.state.customNoteStatus}
              required={this.state.customNoteStatus}
              value={this.state.note}
              onChange={this.handleChange}
            />
            <Typography>User: {this.state.name}</Typography>
          </FormControl>

          <FormControl style={{ display: 'block' }}>
            {this.state.items.map((item, index) => {
              return (
                <div style={{ position: 'relative' }} key={index}>
                  <TextField
                    name='sku'
                    label='SKU'
                    onChange={(e) => this.searchSku(e, index)}
                    variant='outlined'
                    value={item.sku}
                    style={{ marginRight: 15, minWidth: 200 }}
                  />
                  <TextField
                    name='qty'
                    label='QTY'
                    onChange={(e) => this.editQty(e, index)}
                    variant='outlined'
                    value={item.qty}
                    style={{ marginRight: 15, minWidth: 100 }}
                  />
                  <Button onClick={() => this.removeRow(index)}>
                    <CancelIcon />
                  </Button>

                  <span
                    style={{
                      fontSize: 14,
                      display: 'inline-block',
                      bottom: 0,
                      position: 'absolute',
                      color: '#979595',
                    }}>
                    {item.bpName}
                    <br />
                    {item.msg}
                  </span>
                </div>
              )
            })}
          </FormControl>
          <Button
            onClick={(e) => this.addRow(e)}
            variant='outlined'
            style={{ marginTop: 5 }}>
            <AddIcon style={{ marginRight: 5 }} />
            Add Row
          </Button>
          <div style={{ marginTop: 25 }}>
            <Button
              onClick={() => this.processTransfer('S')}
              variant='contained'
              style={{ marginRight: 15 }}
              disabled={this.state.loading || this.state.submitting}>
              Standard Transfer
            </Button>
            <Button
              onClick={() => this.processTransfer('D')}
              variant='contained'
              style={{ marginRight: 15 }}
              disabled={this.state.loading || this.state.submitting}>
              Demo Transfer
            </Button>
            <Button
              onClick={() => this.processTransfer('B')}
              variant='contained'
              style={{ marginRight: 15 }}
              disabled={this.state.loading || this.state.submitting}>
              B-Stock Transfer
            </Button>
            <Button
              onClick={() => this.processTransfer('X')}
              variant='contained'
              style={{ marginRight: 15 }}
              disabled={this.state.loading || this.state.submitting}>
              B-Stock X Transfer
            </Button>
          </div>
        </div>
        <div className='key' style={{ paddingLeft: 15 }}>
          <h2>Standard Transfer</h2>
          <p style={{ fontSize: 16 }}>
            Moves qtys between warehouses via a goods note. FYI the reason you
            add will be set on the goods note and is not visible on the stock
            audit trail
          </p>
          <h2>Demo / B-Stock Transfers</h2>
          <p style={{ fontSize: 16 }}>
            Removes new stock from source warehouse via manual stock correction
            and adds it as demo or b-stock in target warehouse. If a demo /
            b-stock sku doesn't exist it will be automatically created.
          </p>
        </div>
        {(this.state.loading || this.state.submitting) && (
          <LinearProgress
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
            color='secondary'
          />
        )}
      </PaperPage>
    )
  }
}
function mapStateToProps({ brightpearl, inventory, transfer, auth }) {
  return { brightpearl, inventory, transfer, auth }
}
const mapDispatchToProps = {
  fetchWarehouses,
  fetchInventory,
  processTransfer,
  fulfillPreorders,
}
export default connect(mapStateToProps, mapDispatchToProps)(TransferForm)
