import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

// validation functions
// const required = value => (value == null ? 'Required' : undefined);

/** TO DO
 * - add required validation to name
 * - properly wrap TextField in FormControl
 */

const NewIntegration = props => {
  const { onSubmit, submitting, onChange, integrationName } = props;
  return (
    <React.Fragment>
      <div>
        <TextField
          label={'Integration Name'}
          onChange={e => onChange(e.target.value)}
          value={integrationName}
          color="primary"
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onSubmit();
              e.preventDefault();
            }
          }}
          style={{ marginRight: 20 }}
        />
        <Button variant="contained" color="secondary" disabled={submitting} onClick={onSubmit} style={{ verticalAlign: 'bottom' }}>
          Create Token
        </Button>
      </div>
    </React.Fragment>
  );
};

export default NewIntegration;
