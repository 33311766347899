import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPriceGuide } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
import Button from '@material-ui/core/Button';
import ExternalLink from './ExternalLink';

class PriceGuide extends Component {
  state = {
    loading: true,
    expanded: {},
  };
  componentDidMount() {
    this.props.fetchPriceGuide().then((resp) => {
      this.setState({
        loading: false,
      });
    });
  }
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  columns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 140,
      style: { textAlign: 'left' },
      Cell: (row) => {
        let url = `https://www.perfectcircuit.com/${row.original.url_key}.html`;
        return <ExternalLink href={url} content={row.value} />;
      },
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 200,
      style: { textAlign: 'left' },
    },
    {
      Header: 'Name',
      accessor: 'name',
      width: 400,
      style: { textAlign: 'left' },
    },

    {
      Header: 'Price',
      accessor: 'price',
      width: 100,
      Cell: (row) => this.formatter.format(row.value),
    },
    {
      Header: 'Sale Price',
      accessor: 'special_price',
      width: 100,
      style: { textAlign: 'right' },
      Cell: (row) => {
        if (row.value) {
          return this.formatter.format(row.value);
        }
      },
    },
    {
      Header: 'Discount',
      accessor: 'discount',
      width: 100,
      Cell: (row) => {
        if (row.value) {
          return row.value + '%';
        }
      },
    },
    {
      Header: 'On Sale',
      accessor: 'onSale',
      width: 100,
      Cell: (row) => {
        if (row.value) {
          return 'Yes';
        }
      },
    },
    { Header: 'Authorized', accessor: 'authorized_sale', width: 100 },

    { Header: 'From Date', accessor: 'special_from_date', width: 120 },
    { Header: 'To Date', accessor: 'special_to_date', width: 120 },
  ];

  render() {
    return (
      <PaperPage maxWidth='80%'>
        <TopBar
          pageName='Sale Prices'
          subHeading='List of historical and current frontend discounts'
        />

        <ReactTable
          data={this.props.pricing}
          columns={this.columns}
          style={{ textAlign: 'center', border: 0 }}
          loading={this.state.loading}
          loadingText='Loading...'
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          }}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo && rowInfo.row.onSale)
              return { style: { background: '#adffbb' } };
            else return {};
          }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ pricing }) {
  return { pricing };
}
const mapDispatchToProps = {
  fetchPriceGuide,
};
export default connect(mapStateToProps, mapDispatchToProps)(PriceGuide);
