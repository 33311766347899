import React, { Component } from 'react'
import { connect } from 'react-redux'
import axios from 'axios'
import Autocomplete from 'react-autocomplete'

import { createUsed } from '../actions'
import 'react-table/react-table.css'
import Button from '@material-ui/core/Button'
import UsedModal from './UsedModal'

import TextField from '@material-ui/core/TextField'
import Checkbox from '@material-ui/core/Checkbox'
import FormLabel from '@material-ui/core/FormLabel'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import LinearProgress from '@material-ui/core/LinearProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'

import FormControl from '@material-ui/core/FormControl'

import CancelIcon from '@material-ui/icons/Cancel'
import AddIcon from '@material-ui/icons/Add'

class UsedForm extends Component {
  state = {
    submittedBy: this.props.auth.name,
    responseModal: false,
    showContactWarning: false,
    submitting: false,
    loading: false,
    response: {},
    toggle: 'create',

    customerFirstName: '',
    customerLastName: '',
    customerEmail: '',
    customerPhone: '',
    contactSearch: [],
    purchaseOrderId: '',
    orderRef: '',
    dropOff: false,
    products: [
      {
        brand: '',
        name: '',
        notes: '',
        brandId: '',
        newSku: '',
        costPrice: 0,
        needsTesting: true,
        needsPSU: false,
        brandSearch: [],
        productSearch: [],
      },
    ],
  }

  styles = {
    menu: {
      zIndex: 999,
      fontSize: '1rem',
      background: 'white',
      minWidth: 380,
      position: 'absolute',
      left: 'unset',
      top: 'unset',
      border: '1px solid gainsboro',
    },
    menuItem: (isHighlighted) => {
      return {
        padding: 6,
        background: isHighlighted ? '#4095bf' : 'white',
        color: isHighlighted ? 'white' : 'black',
        cursor: 'default',
      }
    },
  }

  handleClose = () => {
    //don't clear out old product info if there was an error
    let newState = {
      responseModal: false,
      showContactWarning: false,
      response: {},
    }
    if (!this.state.response.err) {
      newState = {
        ...newState,
        products: [
          {
            brand: '',
            name: '',
            notes: '',
            brandId: '',
            newSku: '',
            costPrice: 0,
            needsTesting: true,
            needsPSU: false,
            brandSearch: [],
            productSearch: [],
          },
        ],
        customerFirstName: '',
        customerLastName: '',
        customerEmail: '',
        customerPhone: '',
        contactSearch: [],
        purchaseOrderId: '',
        orderRef: '',
        dropOff: false,
        toggle: 'create',
      }
    }

    this.setState(newState)
  }

  submit = () => {
    if (this.state.products.length > 0) {
      this.setState({ submitting: true }, () => {
        this.props.createUsed(this.state).then(() => {
          this.setState({
            submitting: false,
            responseModal: true,
            response: this.props.used.response,
          })
        })
      })
    }
  }

  handleChange = async (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }
  handleCheck = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }
  checkOpenOrders = async (email) => {
    let url = '/api/search/trade-order?email=' + email

    let { data } = await axios.get(url)
    if (data.length > 0) {
      let list = data.map((item) => item.id).join(', ')
      this.setState({ showContactWarning: true, openOrders: list })
    }
  }
  toggle(type) {
    this.setState({ toggle: type })
  }

  addRow() {
    this.setState({
      products: [
        ...this.state.products,
        {
          brand: '',
          name: '',
          notes: '',
          brandId: '',
          newSku: '',
          costPrice: 0,
          needsTesting: true,
          needsPSU: false,
          brandSearch: [],
          productSearch: [],
        },
      ],
    })
  }

  removeRow(index) {
    this.state.products.splice(index, 1)
    this.setState({ products: this.state.products })
  }

  editRow = (e, index) => {
    let val = e.target != undefined ? e.target.value : e
    this.state.products[index][e.target.name] = val
    this.setState({
      products: this.state.products,
    })
  }

  handleCheckRow = (name, index) => (event) => {
    this.state.products[index][name] = event.target.checked
    this.setState({
      products: this.state.products,
    })
  }
  handlSearchProduct = async (e, index) => {
    let nameQuery = e.target.value
    let products = this.state.products
    products[index].name = nameQuery
    products[index].newSku = ''

    this.setState({
      products: products,
    })

    let url = '/api/search/product?name=' + nameQuery
    let brandId = this.state.products[index].brandId
    if (brandId != '') {
      url = url + '&brandId=' + brandId
    }
    let { data } = await axios.get(url)
    products[index].productSearch = data

    this.setState({
      products: products,
    })
  }

  handlSearchBrand = async (e, index) => {
    let brandQuery = e.target.value
    let products = this.state.products
    products[index].brand = brandQuery
    products[index].brandId = ''

    this.setState({
      products: products,
    })

    let url = '/api/search/brand?name=' + brandQuery

    let { data } = await axios.get(url)

    products[index].brandSearch = data

    this.setState({
      products: products,
    })
  }

  handlSearchContact = async (e) => {
    let nameQuery = e.target.value
    this.setState({
      customerFirstName: nameQuery,
    })
    let url = '/api/search/tradeContact?name=' + nameQuery

    let { data } = await axios.get(url)

    this.setState({
      contactSearch: data,
    })
  }
  handlSelectContact = (obj) => {
    this.setState({
      customerFirstName: obj.firstName,
      customerLastName: obj.lastName,
      customerEmail: obj.email,
      customerPhone: obj.phoneNumber ? obj.phoneNumber : '',
      contactSearch: [],
    })
    this.checkOpenOrders(obj.email)
  }

  handleCloseAlert = () => {
    this.setState({ showContactWarning: false, openOrders: null })
  }

  handlSelectProduct = (obj, index) => {
    let products = this.state.products
    if (obj.bpName.indexOf(';') > -1) {
      let brand = obj.bpName.split('; ')[0]
      obj.bpName = obj.bpName.split('; ')[1]

      if (products[index].brand == '') {
        products[index].brand = brand
        products[index].brandId = obj.brandId
      }
    }
    products[index].name = obj.bpName
    products[index].newSku = obj.sku

    this.setState({
      products: products,
    })
  }

  handlSelectBrand = (obj, index) => {
    let products = this.state.products

    products[index].brand = obj.name
    products[index].brandId = obj.bpId

    this.setState({
      products: products,
    })
  }

  render() {
    let readyToSubmit = true
    if (this.state.toggle == 'create') {
      console.log(this.state)
      ;[
        'customerFirstName',
        'customerLastName',
        'customerEmail',
        'customerPhone',
      ].forEach((item) => {
        if (this.state[item] == undefined || this.state[item].length == 0)
          readyToSubmit = false
      })
    } else {
      if (this.state.purchaseOrderId.length == 0) readyToSubmit = false
    }

    this.state.products.forEach((product) => {
      if (product.name == '' || product.brand == '') readyToSubmit = false
    })

    return (
      <div>
        {/* response modal */}
        <UsedModal
          open={this.state.responseModal}
          response={this.state.response}
          handleClose={this.handleClose}
        />

        {/* duplicate contact alert modal */}

        <Dialog
          open={this.state.showContactWarning}
          onClose={this.handleCloseAlert}>
          <DialogContent>
            <DialogContentText>
              This contact already has the following order(s) open:
              {' ' + this.state.openOrders}. Please check to see if you should
              add this trade to an existing order
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseAlert}>Ok</Button>
          </DialogActions>
        </Dialog>

        {/* Submission Form */}
        <div style={{ padding: '15px 15px 0 15px' }}>
          <div style={{ marginBottom: '15px' }}>
            <Button
              onClick={() => this.toggle('create')}
              variant='outlined'
              style={
                this.state.toggle == 'create'
                  ? {
                      borderRadius: '4px 0 0 4px',
                      color: 'white',
                      background: '#34b4c9',
                    }
                  : {
                      borderRadius: '4px 0 0 4px',
                      color: '#757575',
                      borderColor: '#e0e0e0',
                    }
              }>
              New Order
            </Button>
            <Button
              onClick={() => this.toggle('add')}
              variant='outlined'
              style={
                this.state.toggle == 'add'
                  ? {
                      borderRadius: '0 4px 4px 0',
                      color: 'white',
                      background: '#34b4c9',
                    }
                  : {
                      borderRadius: '0 4px 4px 0',
                      color: '#757575',
                      borderColor: '#e0e0e0',
                    }
              }>
              Add to Order
            </Button>
          </div>
          {this.state.toggle == 'create' && (
            <div>
              <h3 style={{ display: 'block' }}>Customer Info</h3>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <FormControl>
                  <Autocomplete
                    items={this.state.contactSearch}
                    inputProps={{
                      placeholder: 'First Name',
                      style: {
                        marginRight: 15,
                        minWidth: 380,
                        fontSize: '1rem',
                        lineHeight: '1.1875em',
                        border: 0,
                        borderBottom: '1px solid #0000006b',
                      },
                    }}
                    menuStyle={this.styles.menu}
                    renderItem={(item, isHighlighted) => (
                      <div style={this.styles.menuItem(isHighlighted)}>
                        {item.fullName + ` [${item.email}]`}
                      </div>
                    )}
                    getItemValue={(item) => item.fullName}
                    value={this.state.customerFirstName}
                    onChange={(e) => this.handlSearchContact(e)}
                    onSelect={(val, obj) => this.handlSelectContact(obj)}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name='customerLastName'
                    label='Last Name'
                    required={true}
                    value={this.state.customerLastName}
                    onChange={this.handleChange}
                    style={{ marginRight: 15, minWidth: 200 }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name='customerEmail'
                    label='Email'
                    required={true}
                    value={this.state.customerEmail}
                    onChange={this.handleChange}
                    onBlur={(e) => this.checkOpenOrders(e.target.value)}
                    style={{ marginRight: 15, minWidth: 200 }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name='customerPhone'
                    label='Phone'
                    required={true}
                    value={this.state.customerPhone}
                    onChange={this.handleChange}
                    style={{ marginRight: 15, minWidth: 200 }}
                  />
                </FormControl>
                <FormControl>
                  <TextField
                    name='orderRef'
                    label='Order Ref'
                    required={false}
                    value={this.state.orderRef}
                    onChange={this.handleChange}
                    style={{ marginRight: 15, minWidth: 200 }}
                  />
                </FormControl>
                <FormControlLabel
                  value='start'
                  control={
                    <Checkbox
                      value='dropOff'
                      name='dropOff'
                      style={{
                        marginLeft: 9,
                        height: 35,
                      }}
                      checked={this.state.dropOff}
                      onChange={this.handleCheck}
                    />
                  }
                  label='Unexpected Dropoff?'
                  labelPlacement='start'
                />
              </div>
            </div>
          )}
          {this.state.toggle == 'add' && (
            <div>
              <span style={{ display: 'block' }}>Order Info</span>

              <FormControl>
                <TextField
                  name='purchaseOrderId'
                  label='Order ID'
                  required={true}
                  value={this.state.purchaseOrderId}
                  onChange={this.handleChange}
                  style={{ marginRight: 15, minWidth: 200 }}
                />
              </FormControl>
            </div>
          )}
          {/* Product Form */}
          <FormControl style={{ display: 'block' }}>
            <h3 style={{ display: 'block', marginTop: 20 }}>Products</h3>
            {this.state.products.map((item, index) => {
              return (
                <div key={index}>
                  <Autocomplete
                    items={this.state.products[index].brandSearch}
                    inputProps={{
                      placeholder: 'search brands',
                      style: {
                        border: 'none',
                        borderBottom: '1px solid #949494',
                        fontSize: '1rem',
                        padding: '6px 0 7px 4px',
                        minWidth: 300,
                        marginTop: 15,
                        marginRight: 15,
                        backgroundColor:
                          this.state.products[index].brandId != ''
                            ? '#2eb72e40'
                            : 'white',
                      },
                    }}
                    menuStyle={this.styles.menu}
                    getItemValue={(item) => item.name}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.bpId}
                        style={this.styles.menuItem(isHighlighted)}>
                        {item.name}
                      </div>
                    )}
                    value={this.state.products[index].brand}
                    onChange={(e) => {
                      return this.handlSearchBrand(e, index)
                    }}
                    onSelect={(val, obj) => this.handlSelectBrand(obj, index)}
                  />

                  <Autocomplete
                    items={this.state.products[index].productSearch}
                    inputProps={{
                      placeholder: 'search products',
                      style: {
                        border: 'none',
                        borderBottom: '1px solid #949494',
                        fontSize: '1rem',
                        padding: '6px 0 7px 4px',
                        minWidth: 380,
                        marginRight: 15,
                        marginTop: 15,
                        backgroundColor:
                          this.state.products[index].newSku != ''
                            ? '#2eb72e40'
                            : 'white',
                      },
                    }}
                    menuStyle={this.styles.menu}
                    getItemValue={(item) => item.bpName}
                    renderItem={(item, isHighlighted) => (
                      <div
                        key={item.bpId}
                        style={this.styles.menuItem(isHighlighted)}>
                        {item.bpName}
                      </div>
                    )}
                    value={this.state.products[index].name}
                    onChange={(e) => this.handlSearchProduct(e, index)}
                    onSelect={(val, obj) => this.handlSelectProduct(obj, index)}
                  />

                  <TextField
                    name='notes'
                    label='Notes'
                    onChange={(e) => this.editRow(e, index)}
                    variant='standard'
                    value={this.state.products[index].notes}
                    style={{
                      marginRight: 15,
                      minWidth: 515,
                    }}
                  />
                  <TextField
                    name='costPrice'
                    label='Cost'
                    onChange={(e) => this.editRow(e, index)}
                    variant='standard'
                    value={this.state.products[index].costPrice}
                    style={{
                      marginRight: 9,
                      width: 80,
                    }}
                  />
                  <FormControl
                    style={{
                      display: 'inline-block',
                      marginRight: 15,
                      width: 75,
                    }}>
                    <FormLabel
                      style={{
                        fontSize: 13,
                      }}>
                      Needs Test
                    </FormLabel>

                    <Checkbox
                      value='needsTesting'
                      style={{
                        marginLeft: 9,
                        height: 35,
                      }}
                      checked={this.state.products[index].needsTesting}
                      onChange={this.handleCheckRow('needsTesting', index)}
                    />
                  </FormControl>
                  <FormControl
                    style={{
                      display: 'inline-block',
                      marginRight: 10,
                      width: 75,
                    }}>
                    <FormLabel
                      style={{
                        fontSize: 13,
                      }}>
                      Needs PSU
                    </FormLabel>

                    <Checkbox
                      style={{
                        marginLeft: 15,
                        height: 35,
                      }}
                      checked={this.state.products[index].needsPSU}
                      onChange={this.handleCheckRow('needsPSU', index)}
                      value='needsPSU'
                    />
                  </FormControl>

                  <Button
                    style={{ padding: '8px 0', minWidth: 40 }}
                    onClick={() => this.removeRow(index)}>
                    <CancelIcon />
                  </Button>
                </div>
              )
            })}
          </FormControl>
          <Button
            onClick={(e) => this.addRow(e)}
            variant='outlined'
            style={{ marginTop: 20 }}>
            <AddIcon style={{ marginRight: 5 }} />
            Add Row
          </Button>
          <div style={{ marginTop: 60 }}>
            <Button
              onClick={() => this.submit()}
              variant='contained'
              style={{ marginRight: 15 }}
              disabled={this.state.submitting || !readyToSubmit}>
              Submit
            </Button>
          </div>
        </div>

        {this.state.submitting && (
          <LinearProgress
            style={{
              position: 'absolute',
              bottom: 0,
              left: 0,
              width: '100%',
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
            }}
            color='secondary'
          />
        )}
      </div>
    )
  }
}

function mapStateToProps({ used, auth }) {
  return { used, auth }
}
const mapDispatchToProps = {
  createUsed,
}
export default connect(mapStateToProps, mapDispatchToProps)(UsedForm)
