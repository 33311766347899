import { FETCH_ETAAUDIT } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_ETAAUDIT:
      return action.payload;
  }
}, []);
