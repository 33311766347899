import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchFauxDemos, demonize } from '../actions';

import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';

import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SalesModal from './SalesModal';
class DemoFauxList extends Component {
  state = {
    loading: true,
    expanded: {},
  };
  componentDidMount() {
    this.props.fetchFauxDemos().then((resp) => {
      this.setState({ loading: false });
    });
  }

  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  handleDemonize = (row) => {
    this.props.demonize([
      {
        bool: !row.value,
        _id: row.original._id,
        type: row.column.id,
      },
    ]);
    row.value = !row.value;
  };

  liveCheck = (row) => {
    if (row.value) {
      return (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '100%',
            padding: '0 20px',
          }}
          onClick={() => this.handleDemonize(row)}>
          <CheckIcon style={{ color: '#000', fontSize: 16 }} />
        </Button>
      );
    } else {
      return (
        <Button
          style={{
            minHeight: 0,
            minWidth: 0,
            height: '100%',
            padding: '0 20px',
          }}
          onClick={() => this.handleDemonize(row)}
        />
      );
    }
  };

  textFilter = ({ filter, onChange }) => {
    return (
      <select
        onChange={(event) => {
          return onChange(event.target.value);
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : 'all'}>
        <option value='all'>All</option>
        <option value='yes'>Yes</option>
        <option value='no'>No</option>
      </select>
    );
  };

  render() {
    return (
      <div>
        <ReactTable
          data={this.props.fauxDemo}
          columns={[
            {
              Header: 'SKU',
              accessor: 'sku',
              width: 150,
              filterMethod: (filter, row) => {
                let rowValue =
                  row[filter.id] == undefined ? '' : row[filter.id];
                return (
                  rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
                );
              },
            },
            {
              Header: 'Brand',
              accessor: 'm2.brand',
              width: 150,
              filterMethod: (filter, row) => {
                let rowValue =
                  row[filter.id] == undefined ? '' : row[filter.id];
                return (
                  rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
                );
              },
            },
            {
              Header: 'Name',
              accessor: 'm2.name',
              width: 480,
              filterMethod: (filter, row) => {
                let rowValue =
                  row[filter.id] == undefined ? '' : row[filter.id];
                return (
                  rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) >
                  -1
                );
              },
            },
            {
              Header: 'Retail',
              accessor: 'm2.price',
              width: 100,
              style: { textAlign: 'right' },
              Cell: (row) => {
                if (row.value > 0) {
                  return this.formatter.format(row.value);
                } else {
                  return null;
                }
              },
            },
            {
              Header: 'Liability',
              accessor: 'liability',
              width: 100,
              Cell: (row) => {
                if (row.value > 0) {
                  return this.formatter.format(row.value);
                } else {
                  return null;
                }
              },
            },
            { Header: 'Status', accessor: 'bpStatus', width: 75 },
            {
              Header: 'QTY',
              accessor: 'bpOnHand',
              width: 75,
              style: { textAlign: 'right' },
            },
            {
              Header: 'Rate',
              accessor: 'purchasing.rate',
              width: 75,
              style: { textAlign: 'right' },
            },
            {
              Header: () => (
                <div>
                  <span data-tip data-for='shelf'>
                    Shelf
                  </span>
                  <ReactTooltip
                    id='shelf'
                    place='top'
                    type='dark'
                    effect='solid'>
                    Est. shelf life in months
                  </ReactTooltip>
                </div>
              ),
              accessor: 'shelf',
              width: 75,
              style: { textAlign: 'right' },
            },
            {
              Header: () => (
                <div>
                  <span data-tip data-for='sts'>
                    STSLS
                  </span>
                  <ReactTooltip id='sts' place='top' type='dark' effect='solid'>
                    Stock Time Since Last Sale
                  </ReactTooltip>
                </div>
              ),
              accessor: 'purchasing.stockTimeSinceLastSale',
              width: 75,
              style: { textAlign: 'right' },
            },
            {
              Header: 'Clearance',
              accessor: 'brand.blacklist.clearance',
              width: 100,
              style: { textAlign: 'center' },
              Cell: (row) => {
                if (row.value) {
                  return 'X';
                } else {
                  return null;
                }
              },
              Filter: ({ filter, onChange }) =>
                this.textFilter({ filter, onChange }),
            },
            {
              Header: 'New',
              accessor: 'new',
              width: 75,
              style: { textAlign: 'center' },
              Cell: (row) => {
                if (row.value) {
                  return 'X';
                } else {
                  return null;
                }
              },
              Filter: ({ filter, onChange }) =>
                this.textFilter({ filter, onChange }),
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                if (filter.value === 'yes') {
                  return row[filter.id] === true;
                }
                return row[filter.id] !== true;
              },
            },
            {
              Header: 'MAP',
              accessor: 'brand.blacklist.mapStatus',
              width: 125,
            },
            { Header: 'FauxDemo', accessor: 'fauxDemoStatus', width: 100 },
            {
              Header: () => (
                <div>
                  <span data-tip data-for='dis'>
                    Discount
                  </span>
                  <ReactTooltip id='dis' place='top' type='dark' effect='solid'>
                    Current discount on the faux listing
                  </ReactTooltip>
                </div>
              ),
              accessor: 'fauxDemoDiscount',
              width: 120,
              Cell: (row) => {
                if (row.value > 0) {
                  return Math.round(row.value * 100) + '%';
                } else {
                  return null;
                }
              },
            },
            {
              Header: () => (
                <div>
                  <span data-tip data-for='rec'>
                    Recommended
                  </span>
                  <ReactTooltip id='rec' place='top' type='dark' effect='solid'>
                    Over 6 mos shelf life
                  </ReactTooltip>
                </div>
              ),
              accessor: 'recommendation',
              width: 100,
              style: { textAlign: 'center' },
              Cell: (row) => {
                if (row.value) {
                  return 'X';
                } else {
                  return null;
                }
              },
              Filter: ({ filter, onChange }) =>
                this.textFilter({ filter, onChange }),
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                if (filter.value === 'yes') {
                  return row[filter.id] === true;
                }
                return row[filter.id] !== true;
              },
            },
            {
              Header: () => (
                <div>
                  <span data-tip data-for='m2'>
                    M2
                  </span>
                  <ReactTooltip id='m2' place='top' type='dark' effect='solid'>
                    Toggle for the Magento demo listing (fauxDemo attribute)
                  </ReactTooltip>
                </div>
              ),
              headerClassName: 'orange-header',
              className: 'orange',
              accessor: 'fauxDemo',
              width: 75,
              style: { textAlign: 'center' },
              Cell: (row) => this.liveCheck(row),
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                if (filter.value === 'yes') {
                  return row[filter.id] === true;
                }
                return row[filter.id] !== true;
              },
              Filter: ({ filter, onChange }) =>
                this.textFilter({ filter, onChange }),
            },
            {
              Header: () => (
                <div>
                  <span data-tip data-for='3p'>
                    3P
                  </span>
                  <ReactTooltip id='3p' place='top' type='dark' effect='solid'>
                    Toggle for the 3P demo listings (thirdPartyDemo attribute)
                  </ReactTooltip>
                </div>
              ),
              headerClassName: 'blue-header',
              className: 'lblue',
              accessor: 'thirdPartyDemo',
              width: 75,
              style: { textAlign: 'center' },
              Cell: (row) => this.liveCheck(row),
              filterMethod: (filter, row) => {
                if (filter.value === 'all') {
                  return true;
                }
                if (filter.value === 'yes') {
                  return row[filter.id] === true;
                }
                return row[filter.id] !== true;
              },
              Filter: ({ filter, onChange }) =>
                this.textFilter({ filter, onChange }),
            },
            {
              expander: true,
              Header: () => (
                <div>
                  <span data-tip data-for='view'>
                    <strong>Chart</strong>,
                  </span>
                  <ReactTooltip
                    id='view'
                    place='top'
                    type='dark'
                    effect='solid'>
                    Show demand graph
                  </ReactTooltip>
                </div>
              ),
              width: 80,
              Expander: ({ isExpanded, ...rest }) => {
                return (
                  <div>
                    {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </div>
                );
              },
              getProps: (state, rowInfo, column) => {
                return {
                  className: 'show-pointer',
                };
              },
              style: {
                fontSize: 25,
                padding: '0',
                textAlign: 'center',
                userSelect: 'none',
              },
            },
          ]}
          style={{ textAlign: 'left', height: '750px', border: '1px 0 0 0 ' }}
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return rowValue > filter.value;
          }}
          defaultSorted={[
            { id: 'fauxDemo', desc: true },
            { id: 'bpOnHand', desc: true },
          ]}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          loading={this.state.loading}
          loadingText='Loading...'
          expanded={this.state.expanded}
          onExpandedChange={(expanded, index, event) => {
            this.setState({ expanded });
          }}
          SubComponent={(row) => {
            return <SalesModal bpId={row.original.bpId} />;
          }}
        />
      </div>
    );
  }
}

function mapStateToProps({ fauxDemo }) {
  return { fauxDemo };
}
const mapDispatchToProps = {
  fetchFauxDemos,
  demonize,
};
export default connect(mapStateToProps, mapDispatchToProps)(DemoFauxList);
