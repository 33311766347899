import React, { Component } from 'react';
import { connect } from 'react-redux';

import Index from './Index.js';

class Home extends Component {
  render() {
    console.log(this);
    if (this.props.auth) return <Index />;
    return (
      <div style={{ textAlign: 'center', color: 'white' }}>
        <h1>Please Login</h1>
      </div>
    );
  }
}

const mapStatetoProps = ({ auth }) => {
  return { auth };
};

export default connect(mapStatetoProps)(Home);
