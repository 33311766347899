import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchBoughtTogether } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CheckIcon from '@material-ui/icons/Check';

class BoughtTogether extends Component {
  state = {
    loading: false,
  };

  pullBoughtTogether = () => {
    console.log('pull');
    this.setState({ loading: true });
    this.props.fetchBoughtTogether(this.state.sku).then((resp) => {
      console.log(this.state.sku);
      this.setState({ loading: false });
    });
  };

  handleNewInput = (e) => {
    this.setState({ sku: e.target.value });
  };

  textFilter = (filter, row) => {
    let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
    return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
  };

  boolFilter = ({ filter, onChange }) => (
    <select onChange={(event) => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'all'}>
      <option value="all">All</option>
      <option value="true">true</option>
      <option value="false">false</option>
    </select>
  );

  per = (value) => {
    return Math.floor(value * 100) + '%';
  };

  priceColumns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 200,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'Name',
      accessor: 'bpName',
      style: { textAlign: 'left' },
      width: 500,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      },
    },
    {
      Header: 'Attach Rate',
      accessor: 'attachRate',
      width: 150,
      filterMethod: (filter, row) => {
        let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
        return rowValue > parseFloat(filter.value / 100);
      },
      Cell: (row) => {
        if (row.value > 0) {
          return this.per(row.value);
        } else {
          return;
        }
      },
    },
    { Header: 'QTY', accessor: 'numOrders', width: 100, style: { textAlign: 'center' } },
    {
      Header: 'Active',
      accessor: 'active',
      width: 100,
      style: { textAlign: 'center' },
      Cell: (row) => {
        if (row.value) {
          return <CheckIcon />
        } else {
          return;
        }
      },
    },
  ];

  render() {
    return (
      <div>

        <TopBar pageName="Bought Together" subHeading="A filtered selection of sale-eligible items">
          <TopBarRow justify="flex-end">
            <form style={{ padding: 25 }} noValidate autoComplete="off">
              <TextField
                value={this.state.sku}
                onChange={this.handleNewInput}
                id="sku"
                label="sku"
                InputLabelProps={{ shrink: true }}
                placeholder="SKU"
                fullWidth
                margin="normal"
                style={{ marginBottom: 15 }}
              />
            </form>
            <Button variant="contained" onClick={this.pullBoughtTogether} label="Refresh Table" style={{ top: -5, marginLeft: 30 }}>
              Refresh Table
            </Button>
          </TopBarRow>
        </TopBar>
        <ReactTable
          data={this.props.boughtTogether}
          columns={this.priceColumns}
          style={{ textAlign: 'right', border: 0, height: '750px' }}
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return rowValue > parseInt(filter.value);
          }}
          minRows={10}
          pageSize={100}
          className="-striped -highlight"
          loading={this.state.loading}
          loadingText="Loading..."

        />
      </div>

    );
  }
}

function mapStateToProps({ boughtTogether, auth }) {
  return { boughtTogether, auth };
}
const mapDispatchToProps = {
  fetchBoughtTogether,
};
export default connect(mapStateToProps, mapDispatchToProps)(BoughtTogether);
