import React, { Component } from 'react';
import { connect } from 'react-redux';
import DemoFauxList from './DemoFauxList';
import DemoRealList from './DemoRealList';
import { tabQuery } from './custom-js';
import DemoForm from './DemoForm';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ReactTooltip from 'react-tooltip';
import Button from '@material-ui/core/Button';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
// import DownloadIcon from '@material-ui/icons/FileDownload';
import GetAppIcon from '@material-ui/icons/GetApp'


class Demos extends Component {
  state = { tabs: tabQuery(this) };

  tabber = (event, value) => {
    this.setState({ tabs: value });
  };

  render() {
    return (
      <PaperPage maxWidth="100%">
        {/* Header Bar */}
        <TopBar>
          <TopBarRow justify="flex-start">
            <h2 className={'demon'}>Demo Daemon</h2>
            <Button variant="contained" href={'/api/demo?type=faux&format=csv'} label="Export Demos" style={{ top: -5, marginLeft: 30 }}>
              Export Faux Demo Report
              <GetAppIcon style={{ marginLeft: 10 }} />
            </Button>
            <Button variant="contained" href={'/api/demo?type=real&format=csv'} label="Export Demos" style={{ top: -5, marginLeft: 30 }}>
              Export Real Demos
              <GetAppIcon style={{ marginLeft: 10 }} />
            </Button>
          </TopBarRow>
        </TopBar>

        {/* Tabs + Tooltips */}
        <AppBar position="static" style={{ boxShadow: 'none' }}>
          <Tabs value={this.state.tabs} onChange={this.tabber}>
            <Tab data-tip data-for="demo-fake" label="Faux Demos" />
            <Tab data-tip data-for="demo-real" label="Real Demos" />
          </Tabs>
        </AppBar>

        <ReactTooltip id="demo-fake" place="top" type="dark" effect="solid">
          List of current fake demos + creation form
        </ReactTooltip>
        <ReactTooltip id="demo-real" place="top" type="dark" effect="solid">
          List of current real demos
        </ReactTooltip>

        {this.state.tabs === 0 && (
          <div>
            <DemoForm />
            <DemoFauxList />
          </div>
        )}
        {this.state.tabs === 1 && <DemoRealList />}
      </PaperPage>
    );
  }
}

export default connect()(Demos);
