import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchM2Brands } from '../actions';

import ReactTable from 'react-table';
import ReactTooltip from 'react-tooltip';
import BrandForm from './BrandForm';

class BrandTableM2 extends Component {
  state = { m2Loading: true };

  componentDidMount() {
    this.props.fetchM2Brands().then(resp => {
      this.setState({ m2Loading: false });
    });
  }

  textFilter = ({ filter, onChange }) => (
    <select onChange={event => onChange(event.target.value)} style={{ width: '100%' }} value={filter ? filter.value : 'all'}>
      <option value="all">All</option>
      <option value="yes">Yes</option>
      <option value="no">No</option>
    </select>
  );

  m2brandColumns = [
    {
      Header: () => (
        <div>
          <span data-tip data-for="m2-brand">
            Brand
          </span>
          <ReactTooltip id="m2-brand" place="top" type="dark" effect="solid">
            Name of brand on Magento
          </ReactTooltip>
        </div>
      ),
      accessor: 'label',
      width: 300,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowVal = row[filter.id] === undefined || row[filter.id] === null ? '' : row[filter.id];
        return rowVal.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      }
    },
    {
      Header: '# Products',
      accessor: 'numProd',
      width: 100,
      filterMethod: (filter, row) => {
        return row[filter.id] > filter.value;
      }
    },
    {
      Header: 'Music',
      accessor: 'music',
      width: 100,
      Cell: row => (row.value ? 'Yes' : ''),
      Filter: ({ filter, onChange }) => this.textFilter({ filter, onChange }),
      filterMethod: (filter, row) => {
        if (filter.value === 'all') {
          return true;
        }
        if (filter.value === 'yes') {
          return row[filter.id] === true;
        }
        return row[filter.id] !== true;
      }
    },
    {
      Header: 'Index',
      accessor: 'index',
      width: 500,
      style: { textAlign: 'left' },
      filterMethod: (filter, row) => {
        let rowVal = row[filter.id] === undefined || row[filter.id] === null ? '' : row[filter.id];
        return rowVal.toLowerCase().indexOf(filter.value.toLowerCase()) > -1;
      }
    }
  ];

  render() {
    //const queryFilter = columnQuery(this, this.m2brandColumns);

    return (
      <div>
        {/* Submission Form */}
        <BrandForm />

        {/* Brands */}
        <ReactTable
          data={this.props.m2Brand}
          columns={this.m2brandColumns}
          style={{ textAlign: 'center', height: '650px' }}
          showPagination
          loading={this.state.m2Loading}
          loadingText="Loading..."
          filterable
          minRows={10}
          pageSize={100}
        />
      </div>
    );
  }
}

function mapStateToProps({ m2Brand }) {
  return { m2Brand };
}
const mapDispatchToProps = {
  fetchM2Brands
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandTableM2);
