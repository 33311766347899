import React from 'react';

const BrandGuide = (props) => {
  return (
    <div style={{ padding: '20px 50px 50px 50px' }} className='key'>
      <h2>Discount Policy</h2>
      <p>
        The majority of our catalog can be discounted with a private coupon from
        the{' '}
        <a
          href='https://docs.google.com/spreadsheets/d/1NGGIy7gB4six6UBlySQrSqOyRNtbY_oyvHEPvUW3Bus/edit#gid=602063681'
          target='_blank'>
          evergreen coupon sheet
        </a>
        , however, there are some items that are universally exempt from any
        discount:
      </p>
      <ul>
        <li>"No Discounts" Brands (see below)</li>
        <li>Items already on sale</li>
        <li>Demo, B-stock, used</li>
        <li>New or high demand items</li>
      </ul>
      <p>
        If you're in doubt about your ability to offer a coupon you can check
        for the "Low Prices Guaranteed" perk icon on the item's listing. All
        discount-exempt items will have this icon removed.
      </p>
      <p>
        Discounts over 10% must be approved by Bryce or Harry. Some of these
        rules can be bent for high-value customers or to fix a mistake on our
        side, but make sure to approve the deal before offering.
      </p>
      <h3>Price matching</h3>
      <p>
        Most items can be price matched up to 10% off with no further
        investigation. If the discount is deeper than that then we require some
        form of evidence of the discount (screenshots, emails etc.) and the item
        must be in stock at the competing retailer
      </p>
      <h3>Upselling</h3>
      <p>
        When offering a discount, attempt to upsell the customer with
        accessories and other complementary items to pad out the margin we're
        losing on the sale, even if this means offering an in-kind discount on
        those accessories
      </p>
      <h2>Brand MAP Status</h2>
      Ranked by how mad the brand gets at us when we break MAP
      <h3>
        No MAP
        <span role='img' aria-label='custom'>
          😀
        </span>
      </h3>
      Brands we've set pricing for and can adjust freely (Erica)
      <h3>
        MSRP
        <span role='img' aria-label='ml'>
          🙂
        </span>
      </h3>
      Brands that have suggested MSRP but don't enforce MAP (XAOC, ALM)
      <h3>
        MAP Public Coupon
        <span role='img' aria-label='coup'>
          😐
        </span>
      </h3>
      Brands that enforce MAP but don't mind coupons (WMD, Malekko)
      <h3>
        MAP Private Coupon
        <span role='img' aria-label='nocoup'>
          😟
        </span>
      </h3>
      Brands that enforce MAP but tolerate private coupons (Ableton,
      Sequential). Adding a brand to this status will exempt them from all
      public coupons like "CUPID10, "WELCOME10" etc.
      <h3>
        MAP No Coupon
        <span role='img' aria-label='mapstrict'>
          😠
        </span>
      </h3>
      Brands that enforce MAP and don't tolerate any coupons (4MS, Intellijel).
      Adding a brand to this status will exempt them from all coupons, save for
      the ones on the
      <a href='https://docs.google.com/spreadsheets/d/1NGGIy7gB4six6UBlySQrSqOyRNtbY_oyvHEPvUW3Bus/edit#gid=788287747'>
        call-in list
      </a>
      <h3>
        No Discounts
        <span role='img' aria-label='srtict'>
          😡
        </span>
      </h3>
      Brands that allow absolutely no discounts under any circumstances - not
      even in store or over the phone (Buchla, low-margin brands like Native
      Instruments)
    </div>
  );
};
export default BrandGuide;
