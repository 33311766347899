import { FETCH_M2BRANDS, CREATE_BRANDS } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_M2BRANDS:
      return action.payload;
    case CREATE_BRANDS:
      draft.response = action.payload;
      return;
  }
}, []);
