import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchTracking } from '../actions';
import { format } from 'date-fns';
import ReactTable from 'react-table';
import ExternalLink from './ExternalLink';
import ReactTooltip from 'react-tooltip';

class Tracking extends Component {
  state = {
    tabs: 0,
    loading: true
  };
  componentDidMount() {
    this.props.fetchTracking().then(resp => {
      this.setState({ loading: false });
    });
  }

  render() {
    return (
      <div>
        <ReactTable
          data={this.props.tracking}
          columns={[
            { Header: 'Vendor', accessor: 'brandName', width: 200 },
            {
              Header: 'Tracking',
              accessor: 'tracking',
              width: 250,
              Cell: row => {
                if (row.original.service == 'ups') {
                  let url = `https://www.ups.com/track?loc=en_US&tracknum=${row.value}`;
                  return <ExternalLink href={url} content={row.value} />;
                } else if (row.original.service == 'fedex') {
                  let url = `https://www.fedex.com/apps/fedextrack/?tracknumbers=${row.value}`;
                  return <ExternalLink href={url} content={row.value} />;
                } else {
                  return row.value;
                }
              }
            },
            {
              Header: 'Shipped',
              accessor: 'shipment_pickup_date',
              width: 80,
              Cell: row => {
                if (row.value) {
                  return format(row.value, 'MMM D');
                } else {
                  return;
                }
              }
            },
            {
              Header: 'ETA',
              accessor: 'expected_delivery',
              width: 80,
              Cell: row => {
                if (row.value) {
                  return format(row.value, 'MMM D');
                } else {
                  return;
                }
              }
            },
            {
              Header: 'Status',
              width: 130,
              accessor: 'tag',
              Cell: row => {
                return (
                  <div>
                    <span data-tip data-for={'tip' + row.index}>
                      {row.value}
                    </span>
                    <ReactTooltip id={'tip' + row.index} place="top" type="dark" effect="solid">
                      {row.original.message}
                    </ReactTooltip>
                  </div>
                );
              }
            },
            {
              Header: 'Carrier',
              accessor: 'service',
              width: 75
            },
            { Header: 'Origin', width: 75, accessor: 'origin' },
            {
              Header: 'Current',
              accessor: 'current_location',
              width: 150
            },
            { Header: 'Weight', accessor: 'shipment_weight', width: 75, Cell: row => `${row.value} lb` },
            { Header: '# Boxes', width: 75, accessor: 'tracked_count' },
            { Header: 'Items on Order', width: 150, accessor: 'backorders' }
          ]}
          style={{ textAlign: 'left', border: 0 }}
          defaultSorted={[{ id: 'expected_delivery', desc: true }, { id: 'shipment_pickup_date', desc: true }]}
          showPagination
          minRows={10}
          pageSize={100}
          className="-striped -highlight"
          loading={this.state.loading}
          loadingText="Loading..."
        />
      </div>
    );
  }
}

function mapStateToProps({ tracking }) {
  return { tracking };
}
const mapDispatchToProps = {
  fetchTracking
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Tracking);
