import { ADD_DEMO_FAUXBATCH } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case ADD_DEMO_FAUXBATCH:
      return action.payload;
  }
}, []);
