import {
  CREATE_WARRANTY_PO,
  CREATE_RETURN_ORDER,
  CREATE_WARRANTY_BSTOCK,
  FETCH_WARRANTY_ORDER_STATUSES,
  FETCH_WARRANTY_PRODUCTS,
  FETCH_RETURN_ORDERS,
  REMOVE_FROM_WARRANTY_WH,
  REMOVE_BRAND_FROM_WARRANTY_UI,
  SEND_WARRANTY_EMAIL,
  UPDATE_INTERNAL_NOTE,
  UPDATE_INTERNAL_NOTE_UI,
  UPDATE_RMA_NUMBER,
  UPDATE_RMA_NUMBER_UI,
  UPDATE_SERIAL_NUMBER,
  UPDATE_SERIAL_NUMBER_UI,
  UPDATE_TRACKING_NUMBER,
  UPDATE_TRACKING_NUMBER_UI,
  UPDATE_WARRANTY_EMAIL,
  UPDATE_WARRANTY_NOTES_UI,
  UPDATE_WARRANTY_NOTES,
  UPDATE_WARRANTY_ORDER_STATUS,
  UPDATE_WARRANTY_ORDER_STATUS_UI,
  UPDATE_WARRANTY_STATUS_HISTORY,
} from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_WARRANTY_PRODUCTS:
      draft.brands = action.payload;
      return
    case FETCH_RETURN_ORDERS:
      draft.return_orders = action.payload;
      return
    case CREATE_RETURN_ORDER:
      draft.response = action.payload;
      return
    case CREATE_WARRANTY_PO:
      draft.response = action.payload
      return
    case REMOVE_FROM_WARRANTY_WH:
      draft.response = action.payload;
      return
    case UPDATE_WARRANTY_EMAIL:
      draft.response = action.payload;
      return
    case UPDATE_INTERNAL_NOTE:
      draft.response = action.payload;
      return
    case UPDATE_RMA_NUMBER:
      draft.response = action.payload;
      return
    case UPDATE_TRACKING_NUMBER:
      draft.response = action.payload;
      return
    case UPDATE_WARRANTY_NOTES:
      draft.response = action.payload;
      return
    case UPDATE_SERIAL_NUMBER:
      draft.response = action.payload;
      return
    case CREATE_WARRANTY_BSTOCK:
      draft.response = action.payload
      return
    case UPDATE_WARRANTY_STATUS_HISTORY:
      draft.response = action.payload
      return
    case REMOVE_BRAND_FROM_WARRANTY_UI:
      let brandIndex = draft.brands.findIndex((brand) =>
        brand._id == action.payload
      )
      draft.brands.splice(brandIndex, 1)
      return
     case UPDATE_WARRANTY_NOTES_UI:
        let additionalNoteRO = draft.return_orders.find((return_order) => {
        return return_order._id == action.payload.returnOrderId
      })
      additionalNoteRO.notes = action.payload.newNotes
      return
    case UPDATE_INTERNAL_NOTE_UI:
      let noteRO = draft.return_orders.find((return_order) => {
        return return_order._id == action.payload.returnOrderId
      })
      let noteProduct = noteRO.products.find((product) => {
        return product._id == action.payload.roNoteId
      })
      noteProduct.internalNote = action.payload.newNote
      return
    case UPDATE_SERIAL_NUMBER_UI:
      let serialRO = draft.return_orders.find((return_order) => {
        return return_order._id == action.payload.returnOrderId
      })
      let serialProduct = serialRO.products.find((product) => {
        return product._id == action.payload.roNoteId
      })
      serialProduct.serialNumber = action.payload.newSerialNumber
      return
    case UPDATE_RMA_NUMBER_UI:
      let rmaRO = draft.return_orders.find((return_order) => {
        return return_order._id == action.payload.returnOrderId
      })
      rmaRO.rmaNumber = action.payload.newRMANumber
      return
    case UPDATE_TRACKING_NUMBER_UI:
      let trackingRO = draft.return_orders.find((return_order) => {
        return return_order._id == action.payload.returnOrderId
      })
      trackingRO.trackingNumber = action.payload.newTrackingNumber
      return
    case UPDATE_WARRANTY_ORDER_STATUS_UI:
      let statusRO = draft.return_orders.find((return_order) => {
        return return_order._id == action.payload.returnOrderId
      })
      statusRO.status = {
        id: action.payload.statusId,
        name: action.payload.name
      }
      return
    case SEND_WARRANTY_EMAIL:
      draft.response = action.payload;
      return
    case FETCH_WARRANTY_ORDER_STATUSES:
      draft.orderStatuses = action.payload;
      return
    case UPDATE_WARRANTY_ORDER_STATUS:
      draft.response = action.payload;
      return

  }

}, {});
