import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Grid from '@material-ui/core/Grid'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Button from '@material-ui/core/Button'
import ExitIcon from '@material-ui/icons/ExitToApp'
import WarningIcon from '@material-ui/icons/Warning'

import ExternalLink from './ExternalLink'

// Toolbar buttons. `to` is either a single route or object of labels/routes
const buttons = [
  {
    label: 'Used',
    to: {
      Intake: '/used',
      Inventory: '/used-inventory-report',
      'New SKUs': '/used-new-sku',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Sales',
    to: {
      Overstocks: '/sale-calc',
      Purchasing: '/purchasing',
      Demos: '/demo',
      'Top Sellers': '/top-sellers',
      'Price Guide': '/price-guide',
      Margins: '/margin-report',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Product',
    to: {
      Audit: '/audit',
      Brands: '/brands',
      Reverb: '/reverb',
      eBay: '/ebay',
      Related: '/related',
      Configurable: '/configurable',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Warehouse',
    to: {
      Receiving: '/receiving',
      Transfer: '/transfer',
      'Stock Corrections': '/stock-corrections',
      Preorders: '/preorders',
      Warranty: '/warranty',
    },
    authLevel: 'confirmed',
  },
  {
    label: 'Admin',
    to: { Integrations: '/integrations', Users: '/users' },
    authLevel: 'admin',
  },
]

/** TO DO
 * - anchorEl implementation doesn't feel right
 * - open menu on hover
 * - clicking another button closes current menu and opens newly clicked menu/link
 */

class Header extends Component {
  state = {
    anchorEl: null,
    key: null,
  }
  handleMenu = (key) => (event) => {
    this.setState({ anchorEl: event.currentTarget, key })
  }
  handleClose = () => {
    this.setState({ anchorEl: null, key: null })
  }
  buttonConstructor() {
    switch (this.props.auth) {
      case null: // waiting for response
        return
      case false: //logged out
        return (
          <Grid item xs='auto'>
            <Button variant='contained' color='secondary' href='/auth/google'>
              Login With Google
            </Button>
          </Grid>
        )
      default:
        const mappedButtons = buttons.map((button, index) => {
          const key = 'headerButton' + index
          return (
            // Render button if authLevel granted
            this.props.auth[button.authLevel] && (
              <React.Fragment key={'headerFrag' + index}>
                {typeof button.to === 'string' ? (
                  // If button `to` prop is a string, its just a button
                  <Grid item xs='auto'>
                    <Button
                      key={key}
                      color='default'
                      component={Link}
                      to={button.to}>
                      {button.label}
                    </Button>
                  </Grid>
                ) : (
                  // Otherwise `to` is an object with key/value pairs for menu items on dropdown
                  <Grid item xs='auto'>
                    <Button
                      key={key}
                      color='default'
                      onClick={this.handleMenu(key)}>
                      {button.label}
                    </Button>
                    <Menu
                      anchorEl={this.state.anchorEl}
                      style={{ marginTop: 33 }}
                      open={
                        Boolean(this.state.anchorEl) && this.state.key === key
                      }
                      onClose={this.handleClose}>
                      {Object.entries(button.to).map(([label, route]) => {
                        return (
                          <MenuItem
                            key={label + 'menuitem'}
                            component={Link}
                            to={route}
                            onClick={this.handleClose}>
                            {label}
                          </MenuItem>
                        )
                      })}
                    </Menu>
                  </Grid>
                )}
                {/* Separator span */}
                <span
                  style={{
                    backgroundColor: '#c9c9c9',
                    margin: '20px 1px',
                    display: 'block',
                    width: 1,
                    zIndex: 1000,
                  }}
                />
              </React.Fragment>
            )
          )
        })
        return [
          ...mappedButtons,
          <Grid key='logoutGriditem' item xs='auto'>
            <Button key='logoutButton' href='/api/logout'>
              Logout
            </Button>
          </Grid>,
        ]
    }
  }

  render() {
    return (
      <AppBar>
        <Grid container justifyContent='center'>
          <Grid item xs={12} lg={9}>
            <Toolbar style={{ minHeight: 44 }}>
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <Button
                    component={Link}
                    to={'/'}
                    style={{ paddingLeft: 18, paddingRight: 18 }}>
                    {this.props.auth ? (
                      <img
                        style={{ height: 20 }}
                        src={
                          'https://www.perfectcircuit.com/media/wysiwyg/reactor_icon.png'
                        }
                        alt='reactLogo'
                      />
                    ) : (
                      'Perfect Circuit Reactor'
                    )}
                  </Button>
                  {this.props.auth && (
                    <ExternalLink
                      href={
                        'https://sites.google.com/perfectcircuitaudio.com/pcawiki/'
                      }
                      target='_blank'
                      content={
                        <Button style={{ paddingLeft: 18, paddingRight: 18 }}>
                          Wiki
                          <ExitIcon style={{ marginLeft: 10 }} />
                        </Button>
                      }
                    />
                  )}
                  {this.props.auth && (
                    <ExternalLink
                      href={
                        'https://chrome.google.com/webstore/detail/perfect-circuit/paahmdhmapfcbkineialohmhhlonfjad'
                      }
                      target='_blank'
                      content={
                        <Button
                          id='chrome-extension-success'
                          style={{ paddingLeft: 18, paddingRight: 18 }}>
                          Chrome Ext
                          <ExitIcon style={{ marginLeft: 10 }} />
                        </Button>
                      }
                    />
                  )}
                </Grid>
                <Grid item xs={7}>
                  <Grid container justifyContent={'flex-end'} spacing={2}>
                    {this.buttonConstructor()}
                  </Grid>
                </Grid>
              </Grid>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    )
  }
}

const mapStatetoProps = ({ auth }) => {
  return { auth }
}

export default connect(mapStatetoProps)(Header)
