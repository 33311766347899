import { FETCH_DEMOS_SUGGESTIONS, FETCH_DEMOS_REAL } from '../actions/types';

import produce from 'immer';

export default produce((draft, action) => {
  switch (action.type) {
    case FETCH_DEMOS_SUGGESTIONS:
      return action.payload;
    case FETCH_DEMOS_REAL:
      return action.payload;
  }
}, []);
