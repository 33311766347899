import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsedNewSkuReport, associateNewSku } from '../actions';
import 'react-table/react-table.css';
import PaperPage from './PaperPage';
import TopBar from './TopBar';
import ReactTable from 'react-table';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Modal from '@material-ui/core/Modal';
import MergeTypeIcon from '@material-ui/icons/MergeType';
import ExitIcon from '@material-ui/icons/ExitToApp';
// import DownloadIcon from '@material-ui/icons/FileDownload';
import GetAppIcon from '@material-ui/icons/GetApp'

import TextField from '@material-ui/core/TextField';
import ExternalLink from './ExternalLink';
import LinearProgress from '@material-ui/core/LinearProgress';

import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import green from '@material-ui/core/colors/green';

class UsedNewSku extends Component {
  state = {
    submitting: false,
    skuList: '',
    response: '',
    modalOpen: false,
    loading: true,
    draft: 0,
  };

  columns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 120,
    },
    {
      Header: 'Name',
      accessor: 'bpName',
    },
    {
      Header: 'Stage',
      accessor: 'stage',
      width: 100,
    },
    { Header: 'New SKU', accessor: 'newSku', width: 120 },
    {
      Header: 'New Name',
      accessor: 'bp.bpName',
    },
  ];

  responseColumns = [
    {
      Header: 'SKU',
      accessor: 'usedSku',
      width: 100,
    },
    {
      Header: 'Name',
      accessor: 'usedName',
      width: 300,
    },
    {
      Header: 'New SKU',
      accessor: 'newSku',
      width: 100,
    },
    {
      Header: 'New Name',
      accessor: 'newName',
      width: 300,
    },
    {
      Header: 'Message',
      accessor: 'msg',
    },
  ];

  componentDidMount() {
    this.props.fetchUsedNewSkuReport().then((resp) => {
      this.setState({
        loading: false,
      });
    });
  }

  handleSkuChange = (e) => {
    this.setState({ skuList: e.target.value });
  };

  update = () => {
    if (this.state.skuList.length > 0) {
      this.setState({ submitting: true });

      this.props.associateNewSku(this.state.skuList).then(() => {
        this.setState(this.submitted);
      });
    }
  };

  submitted = () => {
    this.setState({
      submitting: false,
      skuList: '',
      modalOpen: true,
      response: this.props.response,
    });
  };

  closeModal = () => {
    this.setState({ modalOpen: false, submitting: false });
  };

  render() {
    return (
      <PaperPage xs={9}>
        {/* Response modal */}
        <Modal open={this.state.modalOpen}>
          <Paper
            style={{
              position: 'absolute',
              top: `20%`,
              left: `calc(50% - 600px)`,
              width: 1200,
              height: 400,
              outline: 0,
              padding: 25,
            }}>
            <ReactTable
              data={this.props.used.response}
              columns={this.responseColumns}
              style={{ height: '300px' }}
              className='-striped -highlight'
              getTrProps={(state, rowInfo, column) => {
                console.log(rowInfo);
                if (rowInfo && rowInfo.original.err)
                  return {
                    style: { background: red['500'] },
                  };
                else return {};
              }}
            />
            <Button
              style={{ marginTop: 15 }}
              onClick={this.closeModal}
              variant='contained'
              color='secondary'>
              ok
            </Button>
          </Paper>
        </Modal>

        {/* Header Bar */}
        <TopBar pageName='Used New SKU Report' />

        {/* Submission Form */}
        <form style={{ padding: 25 }} noValidate autoComplete='off'>
          <TextField
            value={this.state.skuList}
            onChange={this.handleSkuChange}
            id='sku'
            label='Upload Associations'
            InputLabelProps={{ shrink: true }}
            placeholder='e.g. U23-0012,N13-1234|U23-0013,N13-1235'
            fullWidth
            margin='normal'
            style={{ marginBottom: 15 }}
          />
          <Button
            onClick={this.update}
            variant='contained'
            disabled={this.state.submitting}
            color='secondary'>
            Associate
            <MergeTypeIcon style={{ marginLeft: 10 }} />
          </Button>
          {this.state.submitting && (
            <LinearProgress
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                width: '100%',
                borderBottomLeftRadius: 4,
                borderBottomRightRadius: 4,
              }}
              color='secondary'
            />
          )}
        </form>

        {/* Listings */}
        <ReactTable
          data={this.props.used.newSkuReport}
          columns={this.columns}
          showPagination
          style={{ height: '550px' }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
          filterable
          defaultFilterMethod={(filter, row) =>
            row[filter.id].toLowerCase().indexOf(filter.value.toLowerCase()) >
            -1
          }
          loading={this.state.loading}
          loadingText='Loading...'
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ used }) {
  return { used };
}
const mapDispatchToProps = {
  fetchUsedNewSkuReport,
  associateNewSku,
};
export default connect(mapStateToProps, mapDispatchToProps)(UsedNewSku);
