import React from 'react';
import Grid from '@material-ui/core/Grid';

const TopBarRow = props => {
  let margin;
  switch (props.justify) {
    case 'flex-start':
      margin = `0 ${props.spacing}px 0 0`;
      break;
    case 'flex-end':
      margin = `0 0 0 ${props.spacing}px`;
      break;
    case 'center':
      margin = `0 ${props.spacing / 2}px`;
      break;
    default:
      margin = '0';
      break;
  }
  return (
    <Grid container justifyContent={props.justify} alignItems="flex-end" style={{ minHeight: 55 }}>
      {React.Children.map(props.children, (child, index) => {
        return (
          <Grid item xs={'auto'} style={{ margin: margin }}>
            {child}
          </Grid>
        );
      })}
    </Grid>
  );
};

export default TopBarRow;
