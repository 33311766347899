import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchMarginReport } from '../actions'
import ReactTable from 'react-table'
import TopBar from './TopBar'
import ReactTooltip from 'react-tooltip'

import TopBarRow from './TopBarRow'
import PaperPage from './PaperPage'
import Button from '@material-ui/core/Button'
import ExternalLink from './ExternalLink'

class MarginReport extends Component {
  state = {
    loading: true,
  }
  componentDidMount() {
    this.props.fetchMarginReport().then((resp) => {
      this.setState({
        loading: false,
      })
    })
  }
  formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  })

  columns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 140,
      style: { textAlign: 'left' },
    },
    {
      Header: 'Name',
      accessor: 'bpName',
      width: 400,
      style: { textAlign: 'left' },
    },

    {
      Header: () => (
        <div>
          <span data-tip data-for='avg'>
            avgCost
          </span>
          <ReactTooltip id='avg' place='top' type='dark' effect='solid'>
            Average PO cost over the past year
          </ReactTooltip>
        </div>
      ),
      accessor: 'avgCost',
      width: 100,
      style: { textAlign: 'right' },
      Cell: (row) => {
        if (row.value) {
          return this.formatter.format(row.value)
        }
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='current'>
            currentCost
          </span>
          <ReactTooltip id='current' place='top' type='dark' effect='solid'>
            Current landed cost of inventory on hand
          </ReactTooltip>
        </div>
      ),
      accessor: 'currentCost',
      width: 100,
      style: { textAlign: 'right' },
      Cell: (row) => {
        if (row.value) {
          return this.formatter.format(row.value)
        }
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='price'>
            price
          </span>
          <ReactTooltip id='price' place='top' type='dark' effect='solid'>
            Current frontend price
          </ReactTooltip>
        </div>
      ),
      accessor: 'price',
      width: 100,
      style: { textAlign: 'right' },
      Cell: (row) => {
        if (row.value) {
          return this.formatter.format(row.value)
        }
      },
    },
    {
      Header: () => (
        <div>
          <span data-tip data-for='avgSale'>
            avgSalePrice
          </span>
          <ReactTooltip id='avgSale' place='top' type='dark' effect='solid'>
            Average sale price over the past year
          </ReactTooltip>
        </div>
      ),
      accessor: 'avgSalePrice',
      width: 100,
      style: { textAlign: 'right' },
      Cell: (row) => {
        if (row.value) {
          return this.formatter.format(row.value)
        }
      },
    },
    {
      Header: 'Margin',
      accessor: 'margin',
      width: 100,
      Cell: (row) => {
        if (row.value) {
          return Math.round(row.value * 100) + '%'
        }
      },
    },
    {
      Header: 'Employee',
      accessor: 'employeePrice',
      width: 100,
      style: { textAlign: 'right' },
      Cell: (row) => {
        if (row.value) {
          if (row.value == '-') return row.value
          return this.formatter.format(row.value)
        }
      },
    },
    {
      Header: 'Warning',
      accessor: 'warn',
      width: 120,
      Cell: (row) => {
        if (row.value) {
          return 'X'
        }
      },
    },
  ]

  render() {
    return (
      <PaperPage maxWidth='80%'>
        <TopBar
          pageName='Margin Report'
          subHeading='Cost breakdown + employee pricing'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/margin-report?type=csv'}
              label='Export'
              style={{ marginLeft: 30 }}>
              Export
            </Button>
          </TopBarRow>
        </TopBar>

        <ReactTable
          data={this.props.pricing}
          columns={this.columns}
          style={{ textAlign: 'center', border: 0 }}
          loading={this.state.loading}
          loadingText='Loading...'
          showPagination
          filterable
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id]
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            )
          }}
          getTrProps={(state, rowInfo, column) => {
            if (rowInfo && rowInfo.row.onSale)
              return { style: { background: '#adffbb' } }
            else return {}
          }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
        />
      </PaperPage>
    )
  }
}

function mapStateToProps({ pricing }) {
  return { pricing }
}
const mapDispatchToProps = {
  fetchMarginReport,
}
export default connect(mapStateToProps, mapDispatchToProps)(MarginReport)
