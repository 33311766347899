import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchUsedInventoryReport } from '../actions';
import ReactTable from 'react-table';
import TopBar from './TopBar';
import TopBarRow from './TopBarRow';
import PaperPage from './PaperPage';
import Button from '@material-ui/core/Button';
import ExternalLink from './ExternalLink';

class UsedInventoryReport extends Component {
  state = {
    loading: true,
    brands: [],
    warehouses: [],
    letters: [],
  };
  componentDidMount() {
    this.props.fetchUsedInventoryReport().then((resp) => {
      let brands = [];
      let warehouses = [];
      let letters = [];
      this.props.used.inventoryReport.forEach((product) => {
        if (brands.indexOf(product.brand) == -1) {
          brands.push(product.brand);
        }
        if (warehouses.indexOf(product.warehouse) == -1) {
          warehouses.push(product.warehouse);
        }
        if (letters.indexOf(product.letter) == -1) {
          letters.push(product.letter);
        }
        brands = brands.sort(function (a, b) {
          return a.toLowerCase().localeCompare(b.toLowerCase());
        });
        warehouses = warehouses.sort();
        letters = letters.sort();
      });
      this.setState({
        loading: false,
        brands: brands,
        warehouses: warehouses,
        letters: letters,
      });
      console.log(this.state);
    });
  }

  whFilter = ({ filter, onChange }) => {
    return (
      <select
        onChange={(event) => {
          return onChange(event.target.value);
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : ''}>
        <option value=''></option>;
        {this.state.warehouses.map((wh) => {
          return (
            <option key={wh} value={wh}>
              {wh}
            </option>
          );
        })}
      </select>
    );
  };

  brandFilter = ({ filter, onChange }) => {
    return (
      <select
        onChange={(event) => {
          return onChange(event.target.value);
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : ''}>
        <option value=''></option>;
        {this.state.brands.map((br) => {
          return (
            <option key={br} value={br}>
              {br}
            </option>
          );
        })}
      </select>
    );
  };

  letterFilter = ({ filter, onChange }) => {
    return (
      <select
        onChange={(event) => {
          return onChange(event.target.value);
        }}
        style={{ width: '100%' }}
        value={filter ? filter.value : ''}>
        <option value=''></option>;
        {this.state.letters.map((letter) => {
          return (
            <option key={letter} value={letter}>
              {letter}
            </option>
          );
        })}
      </select>
    );
  };

  columns = [
    {
      Header: 'SKU',
      accessor: 'sku',
      width: 200,
      style: { textAlign: 'left' },
      Cell: (row) => {
        if (row.value) {
          let url = `https://use1.brightpearlapp.com/p.php?p=report&report=product-audittrail&report_zone=products&report-type=product-audittrail&report_submit=1&numResults=500&productId=${row.original.bpId}`;
          return <ExternalLink href={url} content={row.value} />;
        }
      },
    },
    {
      Header: 'Letter',
      accessor: 'letter',
      width: 75,
      style: { textAlign: 'left' },
      Filter: ({ filter, onChange }) => this.letterFilter({ filter, onChange }),
      filterMethod: (filter, row) => row.letter == filter.value,
    },
    {
      Header: 'Brand',
      accessor: 'brand',
      width: 200,
      style: { textAlign: 'left' },
      Filter: ({ filter, onChange }) => this.brandFilter({ filter, onChange }),
      filterMethod: (filter, row) => row.brand == filter.value,
    },
    {
      Header: 'Name',
      accessor: 'name',
      style: { textAlign: 'left' },
    },

    {
      Header: 'Warehouse',
      accessor: 'warehouse',
      width: 200,
      Filter: ({ filter, onChange }) => this.whFilter({ filter, onChange }),
      filterMethod: (filter, row) => row.warehouse == filter.value,
    },
  ];

  render() {
    return (
      <PaperPage>
        <TopBar pageName='Used Inventory Report'>
          <TopBarRow justify='flex-end'>
            <Button
              variant='contained'
              href={'/api/used-inventory-report?format=csv'}
              label='Export'
              style={{ top: -5, marginLeft: 30 }}>
              Export
            </Button>
          </TopBarRow>
        </TopBar>
        <ReactTable
          data={this.props.used.inventoryReport}
          columns={this.columns}
          style={{ textAlign: 'center', border: 0 }}
          defaultSorted={[{ id: 'brand', desc: false }]}
          showPagination
          filterable
          loading={this.state.loading}
          loadingText='Loading...'
          defaultFilterMethod={(filter, row) => {
            let rowValue = row[filter.id] == undefined ? '' : row[filter.id];
            return (
              rowValue.toLowerCase().indexOf(filter.value.toLowerCase()) > -1
            );
          }}
          minRows={10}
          pageSize={100}
          className='-striped -highlight'
        />
      </PaperPage>
    );
  }
}

function mapStateToProps({ used }) {
  return { used };
}
const mapDispatchToProps = {
  fetchUsedInventoryReport,
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsedInventoryReport);
